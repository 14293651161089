import type { Resolution } from '@backmarket/http-api/src/api-specs-resolution-engine/types/getResolution'

import { formatCustomerIssue } from '@/scopes/customer-care/common/utils/tracking'

export const getHelpRequestCreatedTrackingPayload = (
  resolution: Resolution,
) => {
  const formattedIssues = formatCustomerIssue(
    resolution.problemContext.problem.issues,
  )

  return {
    zone: 'resolution_flow',
    orderlineId: resolution.problemContext.orderline.id,
    resolutionId: resolution.id,
    customerIssues: formattedIssues,
    resolutionOption: resolution.resolutionOption.type,
    resolutionOptionRequested:
      resolution.resolutionOption.fallbackOf?.type ||
      resolution.resolutionOption.type,
  }
}
