export const INPUT_FILE_UPLOAD_ACCEPTED_FILE_EXTENSIONS = [
  // PNG
  '.png',
  'image/png',

  // JPG
  '.jpg',
  'image/jpg',
  '.jpeg',
  'image/jpeg',

  // HEIF
  '.heic',
  'image/heic',
  '.heif',
  'image/heif',

  // PDF
  '.pdf',
  'application/pdf',
]

export const INPUT_FILE_UPLOAD_ERROR_TYPES = {
  MAX_FILE_SIZE_EXCEEDED: 'MAX_FILE_SIZE_EXCEEDED',
  MAX_FILES_SIZE_EXCEEDED: 'MAX_FILES_SIZE_EXCEEDED',
  WRONG_FILE_EXTENSION: 'WRONG_FILE_EXTENSION',
}
