import { navigateTo } from '#imports'

import {
  CLAIM_KIND_ENUM,
  DECLARE_WARRANTY_STATE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { useCareLogger } from '@backmarket/nuxt-layer-care-platform/composables/logger/useCareLogger'
import { useIntercomStore } from '@backmarket/nuxt-layer-care-platform/stores/intercom/useIntercomStore'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { storeToRefs } from 'pinia'
import {
  type BaseActionObject,
  type InternalMachineOptions,
  type ResolveTypegenMeta,
  type ServiceMap,
  assign,
} from 'xstate'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'
import { CUSTOMER_CONVERSATION } from '~/scopes/customer-conversation/route-names'

import { logServiceError } from '../../common/machine/actions/logError'
import { CUSTOMER_CARE } from '../../route-names'

import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
  ResolutionOptionsEvents,
} from './ResolutionFlow.machine.types'
import { getHelpRequestCreatedTrackingPayload } from './selectors/helpRequestCreatedTracking'
import {
  getSelfTroubleshootingCustomerIssues,
  getZendeskArticleIds,
} from './selectors/selfTroubleshootingArticles'
import { UploadFailedError } from './services/initiateResolution'

export const ResolutionFlowMachineActions: InternalMachineOptions<
  MachineContext,
  MachineEvents,
  ResolveTypegenMeta<
    MachineStateSchema,
    MachineEvents,
    BaseActionObject,
    ServiceMap
  >,
  true
>['actions'] = {
  clearActiveContactForm: assign({
    remoteAssistanceContactForm: false,
    warrantyExclusionContactForm: false,
  }),
  clearCustomerInputResolutionOptions: assign({
    customerInput: (context) => {
      return {
        ...context.customerInput,
        resolutionOptions: [],
      }
    },
  }),
  clearShipment: assign({
    shipment: null,
  }),
  clearShipmentIfNewResolutionOption: assign({
    shipment: ({ customerInput, shipment }, event: ResolutionOptionsEvents) => {
      const newResolutionOption = event.payload?.resolutionOption.type

      /**
       * If the customer has selected the same resolution option again,
       * We keep the generated shipment
       */
      const hasSelectedTheSameResolutionOption =
        customerInput?.resolutionOptions?.some(
          (option) => option.type === newResolutionOption,
        )

      return hasSelectedTheSameResolutionOption ? shipment : null
    },
  }),
  navigateToDiagnosisFlow: ({ orderlineId }) => {
    void navigateTo({
      name: CUSTOMER_CARE.DIAGNOSIS.SELF,
      params: {
        orderlineId,
      },
    })
  },
  navigateToContactConfirmation: ({ initiatedResolution }) => {
    void navigateTo({
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
      params: {
        resolutionId: initiatedResolution?.id,
      },
    })
  },
  navigateToConversation: ({ initiatedResolution }) => {
    const route = CUSTOMER_CONVERSATION.RESOLUTION

    void navigateTo({
      name: route,
      params: {
        resolutionId: initiatedResolution?.id,
      },
    })
  },

  navigateToReturnConfirmation: ({ initiatedResolution }) => {
    void navigateTo({
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
      params: {
        resolutionId: initiatedResolution?.id,
      },
    })
  },

  navigateToExpressReplacement: () => {
    void navigateTo({
      name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.DETAILS,
    })
  },

  navigateToStraightRefundConfirmation: ({ initiatedResolution }) => {
    void navigateTo({
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
      params: {
        resolutionId: initiatedResolution?.id,
      },
    })
  },
  logErrorFromCreateShipmentService: (_, event) => {
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service create shipment`,
      event,
    )
  },
  logErrorFromGetIssueDataService: (_, event) => {
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service get issues`,
      event,
    )
  },
  logErrorFromGetResolutionOptionsService: (_, event) => {
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service get resolution options`,
      event,
    )
  },
  logErrorFromInitiateResolutionService: (_, event) => {
    // Upload errors are logged by the upload service package.
    if (event.data instanceof UploadFailedError) {
      return
    }
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service initiate resolution`,
      event,
    )
  },
  logErrorFromOrderlineTimelineService: (_, event) => {
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service get orderline timeline`,
      event,
    )
  },
  logErrorFromGetSelftroubleshootingArticlesService: (_, event) => {
    logServiceError(
      `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} error on service get self troubleshooting articles`,
      event,
    )
  },
  logErrorUnhandledResolutionSelection: (
    { orderlineId },
    event: ResolutionOptionsEvents,
  ) => {
    const { logFeatureError } = useSalesCustomerCareLogger()
    logFeatureError({
      errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} unhandled resolution selection`,
      errorDetail: {
        orderlineId,
        selectedResolutionOption: event.payload?.resolutionOption?.type,
      },
      featureName: 'resolution_flow_unhandled_resolution_selection',
    })
  },
  showIntercomMessanger: (_, event) => {
    const intercomTicketId = event.data.resolution?.externalId
    const orderlineId = event.data.resolution?.problemContext?.orderline?.id

    const { logFeatureError } = useCareLogger()
    const { showConversation } = useIntercomStore()

    if (intercomTicketId) {
      showConversation(intercomTicketId)
      void navigateTo(
        {
          query: {
            intercom_ticket_id: intercomTicketId,
          },
        },
        {
          replace: true,
        },
      )
    } else {
      logFeatureError({
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} [Intercom] - Error trying to open showConversation without tiket id`,
        errorDetail: {
          orderlineId,
          intercomTicketId,
        },
        featureName: 'resolution_flow_show_intercom_conversation',
      })
    }
  },
  hideIntercomMessanger: (_) => {
    const intercomStore = useIntercomStore()
    const { hide } = intercomStore
    const { intercom } = storeToRefs(intercomStore)
    if (intercom.value.isAvailable) {
      hide()
    }
  },
  storeOrderlineLifecycleContactFormAsActive: assign({
    orderlineLifecycleContactForm: true,
  }),
  storeRemoteAssistanceContactFormAsActive: assign({
    remoteAssistanceContactForm: true,
  }),
  storeWarrantyExclusionContactFormAsActive: assign({
    warrantyExclusionContactForm: true,
  }),
  storeContactAsFirstState: assign({
    firstStateAfterLoading: 'CONTACT.FORM',
  }),
  storeContactFormPayload: assign({
    customerInput: ({ customerInput }, event) => {
      return {
        ...customerInput,
        description: event.payload.description,
        pictures: event.payload.pictures,
      }
    },
  }),
  storeInitiatedResolutionPayload: assign({
    initiatedResolution: (_, event) => event.data.resolution,
  }),
  storeIssueData: assign({
    diagnosisV2Action: ({ diagnosisV2Action }, event) => {
      return isEmpty(event.data)
        ? diagnosisV2Action
        : event.data.customerIssuesAction
    },
  }),
  storeOrderlineLifecycleLateDeliveryAsFirstState: assign({
    firstStateAfterLoading: 'ORDERLINE_LIFECYCLE.ORDERLINE_LATE_DELIVERY',
  }),
  storeOrderlineLifecycleNotShippedAsFirstState: assign({
    firstStateAfterLoading: 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_SHIPPED',
  }),
  storeOrderlineLifecycleNotValidatedAsFirstState: assign({
    firstStateAfterLoading: 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_VALIDATED',
  }),
  storeOrderlineTimelinePayload: assign({
    orderlineTimeline: (_, event) => event.data,
  }),
  storeOutOfWarrantyPayload: assign({
    customerInput: ({ customerInput }, event) => {
      return {
        ...customerInput,
        declaredWarrantyState: event?.payload?.declaredWarrantyState
          ? event.payload.declaredWarrantyState
          : DECLARE_WARRANTY_STATE_ENUM.inWarranty,
      }
    },
  }),
  storePointOfContactBmWarrantyClaim: assign({
    pointOfContact: CLAIM_KIND_ENUM.bmWarranty,
  }),
  storePointOfContactMerchantClaim: assign({
    pointOfContact: CLAIM_KIND_ENUM.merchant,
  }),
  storeLateDeliveryFormAsFirstStateAfterLoading: assign({
    firstStateAfterLoading: 'CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM',
  }),
  storeNotShippedFormAsFirstStateAfterLoading: assign({
    firstStateAfterLoading: 'CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM',
  }),
  storeNotValidatedFormAsFirstStateAfterLoading: assign({
    firstStateAfterLoading: 'CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM',
  }),
  storeResolutionOptionPayload: assign({
    customerInput: ({ customerInput }, event: ResolutionOptionsEvents) => {
      return {
        ...customerInput,
        resolutionOptions: [event.payload.resolutionOption],
      }
    },
  }),
  storeResolutionOptionsData: assign({
    resolutionOptions: ({ resolutionOptions }, event) => {
      if (isEmpty(event.data)) {
        return resolutionOptions
      }

      return event.data.resolutionOptions
    },
    repairOptionsUnavailable: (_, event) => {
      return event.data.displayNoRepairTooltip ?? false
    },
  }),
  storeSelfTroubleshootingArticles: assign({
    selfTroubleshootingArticles: (_, event) => event.data,
  }),
  storeSelfTroubleshootingAsFirstState: assign({
    firstStateAfterLoading: 'SELF_TROUBLESHOOTING',
  }),
  storeShipment: assign({
    shipment: ({ shipment }, event) => {
      if (isEmpty(event.data)) {
        return shipment
      }

      return event.data.shipment
    },
  }),
  storeXrData: assign({
    xrData: (_, event) => event.data,
  }),
  trackScreenViewSelfTroubleshooting: (context) => {
    useTracking().trackScreenView({
      screenType: 'selfcare_content_diagno',
      orderlineId: context.orderlineId as number,
      customerIssues: getSelfTroubleshootingCustomerIssues(context),
      zendeskArticleIds: getZendeskArticleIds(context),
    })
  },
  trackClickSelfTroubleshootingArticle: (_context, event) => {
    useTracking().trackClick({
      pageType: 'selfcare_content_diagno',
      zone: 'selfcare_content_links',
      zendeskArticleId: event.articleId,
    })
  },
  trackClickSelfTroubleshootingContinue: () => {
    useTracking().trackClick({
      name: 'need_help',
      pageType: 'selfcare_content_diagno',
      zone: 'need_help_button',
    })
  },
  trackScreenViewResolutionOption: ({
    diagnosisV2CustomerIssues,
    diagnosisV2CustomerIssuesTags,
    orderlineId,
    resolutionOptions,
  }) => {
    useTracking().trackScreenView({
      screenType: 'resolution_options',
      zone: 'resolution_flow',
      orderlineId: orderlineId as number,
      diagnoType: diagnosisV2CustomerIssuesTags,
      customerIssues: diagnosisV2CustomerIssues,
      resolutionsAvailable: resolutionOptions?.map(({ type }) => type) ?? [],
    })
  },
  trackClickResolutionOptionCard: (
    _context,
    event: ResolutionOptionsEvents,
  ) => {
    if (event.type === 'CONTINUE') {
      useTracking().trackClick({
        name: event.payload.resolutionOption.type,
        pageType: 'resolution_options',
        zone: 'resolution_flow',
      })
    }
  },
  trackHelpRequestCreated: (_, event) => {
    const { resolution } = event.data

    if (!resolution) {
      return
    }

    const eventPayload = getHelpRequestCreatedTrackingPayload(resolution)

    useTracking().trackHelpRequestCreated(eventPayload)
  },
}
