export default {
  noPhotosHeading: {
    id: 'dashboard_diagnosis_photo_step_modal_title',
    defaultMessage: 'No photos?',
  },
  noPhotosSuggestion: {
    id: 'dashboard_diagnosis_photo_step_modal_text',
    defaultMessage:
      'We suggest you attach at least 4 photos of your item in case something happens to it in transit.',
  },
  noThanks: {
    id: 'dashboard_diagnosis_photo_step_modal_cancel',
    defaultMessage: 'No thanks',
  },
  addPhotosNow: {
    id: 'dashboard_diagnosis_photo_step_modal_cta',
    defaultMessage: 'Add photos now',
  },
}
