export default {
  confirmCheckboxContent: {
    id: 'resolution_flow_data_removal_checkbox_content',
    defaultMessage:
      'I signed out from my accounts and removed data and settings. I understand my request cannot be processed otherwise.',
  },
  confirmInfoBlockTitle: {
    id: 'resolution_flow_data_removal_confirm_info_block_title',
    defaultMessage: 'Hold up!',
  },
  confirmInfoBlockContent: {
    id: 'resolution_flow_data_removal_confirm_info_block_content',
    defaultMessage:
      'Make sure you’ve completed the steps above even if your device won’t turn on. Otherwise your return will be blocked.',
  },
  continue: {
    id: 'dashboard_diagnosis_continue',
    defaultMessage: 'Continue',
  },
  deviceWontTurnOnSectionContent: {
    id: 'resolution_flow_data_removal_device_wont_turn_on_section_content',
    defaultMessage:
      'Device won’t turn on? You can still complete these steps with instructions linked above.',
  },
  stepBackUpContent: {
    id: 'resolution_flow_data_removal_step_back_up_content',
    defaultMessage:
      'First, back up your data before you remove it from your device completely.',
  },
  stepBackUpLink: {
    id: 'resolution_flow_data_removal_step_back_up_link',
    defaultMessage: 'How to back up your data',
  },
  stepRemovePersonalDataContent: {
    id: 'resolution_flow_data_removal_step_remove_personal_info_content',
    defaultMessage:
      'You must sign out from all accounts, remove your personal data, settings, and eSIM (if applicable) or the seller won’t be able to work on your device.',
  },
  stepRemovePersonalDataLink: {
    id: 'resolution_flow_data_removal_step_remove_personal_info_link',
    defaultMessage: 'How to remove personal info',
  },
  subtitle: {
    id: 'resolution_flow_data_removal_subtitle',
    defaultMessage:
      'Follow these steps to get your item ready and submit a return request.',
  },
  title: {
    id: 'resolution_flow_data_removal_title',
    defaultMessage: 'Mandatory data removal steps',
  },
}
