export default {
  backToMyOrders: {
    id: 'dashboard_diagnosis_contact_form_back_to_my_orders',
    defaultMessage: 'Go back to my orders',
  },
  cancelOrderCta: {
    id: 'dashboard_order_status_cancel_order',
    defaultMessage: 'Cancel order',
  },
  easterEggHeader: {
    id: 'dashboard_order_status_easter_egg_header',
    defaultMessage: 'Yippee!',
  },
  easterEggMessage: {
    id: 'dashboard_order_status_easter_egg_message',
    defaultMessage:
      'Now, you can just kick back and enjoy some {link, html} to pass the time.',
  },
  headingOrderNotValidated: {
    id: 'dashboard_order_status_this_is_normal',
    defaultMessage: 'Don’t worry! This is normal',
  },
  headingOrderNotDelivered: {
    id: 'dashboard_order_status_order_will_be_delivered_soon',
    defaultMessage: 'Don’t worry! Your order will be delivered soon.',
  },
  noItsAllGood: {
    id: 'dashboard_order_status_no_all_good',
    defaultMessage: 'No, it’s all good',
  },
  orderlineStatusInBusinessDayDuration: {
    id: 'dashboard_order_status_in_business_days_duration',
    defaultMessage: `in {duration, plural,
        one {# business day}
        other {# business days}
      }`,
  },
  orderlineStatusInDayDuration: {
    id: 'dashboard_order_status_in_days_duration',
    defaultMessage: `in {duration, plural,
        one {# day}
        other {# days}
      }`,
  },
  orderlineStatusInHoursDuration: {
    id: 'dashboard_order_status_hours_duration',
    defaultMessage: `{duration, plural,
        one {# hour}
        other {# hours}
      }`,
  },
  solidReadingMaterial: {
    id: 'dashboard_order_status_solid_reading_material',
    defaultMessage: 'solid reading material',
  },
  statusInfoBlockOrderNotValidated: {
    id: 'dashboard_order_status_stats_within_24_hours',
    defaultMessage: 'About 72% of orders ship within 24 hours.',
  },
  statusShippementInfoBlock: {
    id: 'dashboard_order_status_exclude_weekends',
    defaultMessage:
      'We exclude weekends and public holidays when estimating shipping and delivery times.',
  },
  statusOrderNotValidated: {
    id: 'dashboard_order_status_seller_preparing_order',
    defaultMessage:
      'The seller is preparing your order, which involves getting your item from their stock and packing it up. They still have {duration, html} to ship, in line with our quality standards. Feel free to contact them if it takes longer than this.',
  },
  statusOrderNotShipped: {
    id: 'dashboard_order_status_shipping_should_be_done_in',
    defaultMessage:
      'Shipping should be done {duration, html}. Time passes so slowly when you’re waiting for something awesome, right?',
  },
  statusOrderNotDelivered: {
    id: 'dashboard_order_status_should_reach_in',
    defaultMessage:
      'It should reach you {duration, html}. Time passes so slowly when you’re waiting for something awesome, right?',
  },
  stillHaveQuestions: {
    id: 'dashboard_order_status_still_have_questions',
    defaultMessage: 'Still have questions?',
  },
  yesContactSeller: {
    id: 'dashboard_order_status_yes_contact_seller',
    defaultMessage: 'Yes, contact the seller',
  },
}
