<template>
  <div class="contents">
    <ContactShell
      :secondary-cta-label="i18n(translations.backToOrdersCta)"
      :secondary-cta-to="
        resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })
      "
      :title="i18n(translations.heading)"
      :withfooter-caption="false"
      @submit="submitRequest"
    >
      <template #content>
        <RevInfoBlock
          v-if="infoBlock && infoBlock.date"
          :icon="IconInfo"
          :title="
            i18n(infoBlock.title, {
              date:
                infoBlock.date instanceof Date
                  ? i18n.date(infoBlock.date)
                  : infoBlock.date,
            })
          "
          variant="info"
        >
        </RevInfoBlock>
      </template>
    </ContactShell>

    <RevToast
      :close-alternative-text="
        i18n(translations.errorToastCloseAlternativeTest)
      "
      icon-src="/img/toast/storm.svg"
      name="resolution-flow-remote-assistance-contact-screen-submition-error-toast"
      :opened="hasSubmitError"
      :title="i18n(translations.errorToastTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="handleCloseErrorToast"
    >
      {{ i18n(translations.errorToastDescription) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevToast } from '@ds/components/Toast'
import { IconInfo } from '@ds/icons/IconInfo'

import type {
  ContactEvents,
  MachineSend,
} from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'
import { DASHBOARD } from '@/scopes/dashboard/routes'

import type { ContactFormValues } from '../../../../common/ContactShell/ContactShell.types'
import ContactShell from '../../../../common/ContactShell/ContactShell.vue'

import {
  getInfoBlockContentConfig,
  orderlineLifecycleMatchesStates,
} from './OrderlineLifecycleContactScreen.config'
import translations from './OrderlineLifecycleContactScreen.translations'
import type { OrderlineLifecycleContactScreenProps } from './OrderlineLifecycleContactScreen.types'

const props = defineProps<OrderlineLifecycleContactScreenProps>()

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const send = props.machineSend as MachineSend<ContactEvents>

const infoBlock = computed(() => {
  const activeState = orderlineLifecycleMatchesStates.filter(
    props.machineState.matches,
  )[0]

  const config = getInfoBlockContentConfig(
    props.machineState.context.orderlineTimeline,
  )

  if (config[activeState]) return config[activeState]

  return undefined
})

const hasSubmitError = computed(() =>
  props.machineState.matches('CONTACT.SUBMISSION_ERROR'),
)

function handleCloseErrorToast() {
  send('GO_TO_FORM')
}

function submitRequest({ details, files }: ContactFormValues) {
  send({
    type: 'CONTACT_REFURBISHER',
    payload: {
      description: details,
      pictures: files,
    },
  })
}
</script>
