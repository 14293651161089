import type { StateNodeConfig, StateSchema } from 'xstate'

import type {
  MachineContext,
  MachineEvents,
} from '~/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'
import { ReturnFunnelState } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.state'
import { ReturnFunnelTransitions } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.transitions'

export const ReturnFunnelSchema: StateNodeConfig<
  MachineContext,
  StateSchema<MachineContext>,
  MachineEvents
> = {
  on: ReturnFunnelTransitions,
  states: ReturnFunnelState,
}
