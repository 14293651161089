<template>
  <div
    class="contents"
    data-qa="return-funnel-screen"
    data-test="return-funnel-screen"
  >
    <RevStepper
      :active-step
      :alternative-text-back="i18n(translations.previousPage)"
      :alternative-text-close="i18n(translations.stepperClose)"
      :alternative-text-completed="i18n(translations.stepperCompleted)"
      :alternative-text-current="i18n(translations.stepperCurrent)"
      data-qa="step-header-return-funnel"
      data-test="step-header-return-funnel"
      has-back
      has-close
      :steps
      @back="goBack"
      @close="emit('close')"
    />

    <ContactScreen v-if="isContactScreen" :machine-send :machine-state />

    <DataRemovalScreen v-if="isDataRemovalScreen" :machine-send />

    <PicturesScreen
      v-if="isPicturesScreen"
      :isIntroScreen="isPicturesScreenIntro"
      :machine-send
      :pictures="machineState.context.customerInput.pictures"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevStepper } from '@ds/components/Stepper'
import type { Step } from '@ds/components/Stepper/Stepper.constant'

import { getSelectedResolutionActions } from '~/scopes/customer-care/resolution/machine/selectors/resolutionOption'

import ContactScreen from './ContactScreen/ContactScreen.vue'
import DataRemovalScreen from './DataRemovalScreen/DataRemovalScreen.vue'
import PicturesScreen from './PicturesScreen/PicturesScreen.vue'
import translations from './ReturnScreen.translations'
import type { ReturnScreenProps } from './ReturnScreen.types'

const props = defineProps<ReturnScreenProps>()

const emit = defineEmits(['close'])
const i18n = useI18n()
const resolutionActions = getSelectedResolutionActions(
  props.machineState.context,
)

const steps = resolutionActions
  .map((action) => {
    if (action === 'SHOW_DATA_REMOVAL') {
      return {
        name: 'DATA_REMOVAL_INTRO',
        label: i18n(translations.removeDataStepperLabel),
      }
    }
    if (action === 'SHOW_PICTURES' || action === 'SHOW_PICTURES_WITH_INTRO') {
      return {
        name: 'PICTURES',
        label: i18n(translations.picturesStepperLabel),
      }
    }
    if (action === 'SHOW_MESSAGE') {
      return {
        name: 'DESCRIPTION',
        label: i18n(translations.descriptionStepperLabel),
      }
    }

    return null
  })
  .filter(Boolean) as Step[]
const activeStep = computed(() => {
  const currentStep = Object.values(props.machineState.value)[0]
  if (currentStep === 'PICTURES_INTRO') {
    return 'PICTURES'
  }
  if (currentStep === 'SUBMISSION_ERROR') {
    return 'DESCRIPTION'
  }

  return currentStep as string
})

const isContactScreen = computed(
  () =>
    props.machineState.matches('RETURN_FUNNEL.DESCRIPTION') ||
    props.machineState.matches('RETURN_FUNNEL.SUBMISSION_ERROR'),
)

const isDataRemovalScreen = computed(() =>
  props.machineState.matches('RETURN_FUNNEL.DATA_REMOVAL_INTRO'),
)

const isPicturesScreenIntro = computed(() =>
  props.machineState.matches('RETURN_FUNNEL.PICTURES_INTRO'),
)

const isPicturesScreen = computed(
  () =>
    props.machineState.matches('RETURN_FUNNEL.PICTURES') ||
    isPicturesScreenIntro.value,
)

function goBack() {
  props.machineSend('BACK')
}
</script>
