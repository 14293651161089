export default {
  removeDataStepperLabel: {
    id: 'dashboard_diagnosis_prepare_step_step_title',
    defaultMessage: 'Remove your data',
  },
  descriptionStepperLabel: {
    id: 'dashboard_diagnosis_contact_heading',
    defaultMessage: 'Describe your issue',
  },
  picturesStepperLabel: {
    id: 'dashboard_diagnosis_photo_step_step_title',
    defaultMessage: 'Add photos',
  },
  previousPage: {
    id: 'resolution_flow_stepper_alternative_text_back',
    defaultMessage: 'Back',
  },
  stepperClose: {
    id: 'resolution_flow_stepper_alternative_text_close',
    defaultMessage: 'Close',
  },
  stepperCompleted: {
    id: 'resolution_flow_stepper_alternative_text_completed',
    defaultMessage: 'Completed:',
  },
  stepperCurrent: {
    id: 'resolution_flow_stepper_alternative_text_current',
    defaultMessage: 'Current',
  },
}
