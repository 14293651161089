export default {
  cta: {
    id: 'resolution_flow_technical_self_troubleshooting_screen_cta',
    defaultMessage: 'Continue',
  },
  subtitle: {
    id: 'resolution_flow_technical_self_troubleshooting_screen_subtitle',
    defaultMessage:
      'We picked common troubleshooting solutions for you below, but continue on if you’ve already given them a try.',
  },
  title: {
    id: 'resolution_flow_technical_self_troubleshooting_screen_title',
    defaultMessage: 'Let’s get you started',
  },
  articleListTitle: {
    id: 'resolution_flow_technical_self_troubleshooting_screen_article_title',
    defaultMessage: 'This might help',
  },
}
