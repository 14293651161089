export default {
  defaultTitle: {
    id: 'dashboard_diagnosis_contact_heading',
    defaultMessage: 'Describe your issue',
  },
  expectedAnswer: {
    id: 'dashboard_diagnosis_contact_expected_answer',
    defaultMessage:
      'Within 1 business day, you’ll get an email with a link to our messaging platform — use this to communicate with us about your request.',
  },
  formIntroduction: {
    id: 'dashboard_diagnosis_contact_form_introduction',
    defaultMessage:
      'Provide as much detail as possible to help us fully understand the problem.',
  },
  inputDescriptionLabel: {
    id: 'dashboard_diagnosis_contact_form_description_placeholder',
    defaultMessage: 'Your question or issue',
  },

  submitButton: {
    id: 'dashboard_diagnosis_send_request',
    defaultMessage: 'Send request',
  },
}
