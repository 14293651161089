export const KB = 1024
export const MB = 1024 * KB

/**
 * Default smartphone pictures can be heavy (e.g.: 7.5 MB),
 * And as we suggest to take 7 pictures, we have to be able to handle it.
 */
export const MAX_FILE_SIZE = 10 * MB

/**
 * The Nginx configuration has a 30 MB limit
 */
export const MAX_FILES_SIZE = 30 * MB
