import type { GetResolutionOptionsActionsEnum } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import {
  type ActionObject,
  type BaseActionObject,
  type InternalMachineOptions,
  type ResolveTypegenMeta,
  type ServiceMap,
  assign,
  pure,
  raise,
} from 'xstate'

import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
} from '~/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'
import { ResolutionActionToEvent } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.constants'
import {
  type ReturnFunnelDescriptionEvents,
  type ReturnFunnelMachineEvents,
  type ReturnFunnelPictureEvents,
} from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.types'
import { getPreviousPage } from '~/scopes/customer-care/resolution/machine/selectors/pageHistory'
import { getSelectedResolutionActions } from '~/scopes/customer-care/resolution/machine/selectors/resolutionOption'
/**
 * Action creator which queues up an event name to screenHistory
 */
export function createAddHistoryAction(
  eventName: ReturnFunnelMachineEvents['type'],
): ActionObject<MachineContext, ReturnFunnelMachineEvents> {
  return assign({
    pageHistory: ({ pageHistory }) => [...pageHistory, eventName],
  })
}

export const ReturnFunnelActions: InternalMachineOptions<
  MachineContext,
  MachineEvents,
  ResolveTypegenMeta<
    MachineStateSchema,
    MachineEvents,
    BaseActionObject,
    ServiceMap
  >,
  true
>['actions'] = {
  storeHistoryResolutionOption: createAddHistoryAction('EXIT_RETURN_FUNNEL'),
  storeHistoryDataRemoval: createAddHistoryAction('GO_TO_DATA_REMOVAL'),
  storeHistoryPicturesIntro: createAddHistoryAction('GO_TO_PICTURES_INTRO'),
  storeHistoryPictures: createAddHistoryAction('GO_TO_PICTURES'),
  storeHistoryDescription: createAddHistoryAction('GO_TO_DESCRIPTION'),
  goToPreviousPage: pure((context) => {
    const previousPageEvent = getPreviousPage(context)

    return ['removeLastPage', raise(previousPageEvent)]
  }),
  goToNextPage: pure((context) => {
    const { activeAction } = context
    const resolutionActions = getSelectedResolutionActions(context)
    // first screen
    if (activeAction === null && resolutionActions.length > 0) {
      return raise(ResolutionActionToEvent[resolutionActions[0]])
    }

    let eventName: ReturnFunnelMachineEvents['type']

    const activeActionIndex = resolutionActions.indexOf(
      activeAction as GetResolutionOptionsActionsEnum,
    )
    if (activeActionIndex === resolutionActions.length - 1) {
      // last screen, initiate the resolution
      eventName = 'SEND_REQUEST'
    } else {
      // next screen
      eventName =
        ResolutionActionToEvent[resolutionActions[activeActionIndex + 1]]
    }

    return raise(eventName)
  }),
  removeLastPage: assign(({ pageHistory }) => ({
    pageHistory: pageHistory.slice(0, -1),
  })),
  storeShipment: assign({
    shipment: ({ shipment }, event) => {
      if (isEmpty(event.data)) {
        return shipment
      }

      return event.data.shipment
    },
  }),
  storeDataRemovedPayload: assign({
    customerInput: (
      { customerInput },
      event: {
        type: 'CONTINUE'
        payload: {
          areDataRemoved: boolean
        }
      },
    ) => {
      return {
        ...customerInput,
        areDataRemoved: event.payload.areDataRemoved,
      }
    },
  }),
  storeReturnFunnelPicturesPayload: assign({
    customerInput: ({ customerInput }, event: ReturnFunnelPictureEvents) => {
      return {
        ...customerInput,
        pictures: event?.payload?.pictures,
      }
    },
  }),
  storeReturnFunnelDescriptionPayload: assign({
    customerInput: (
      { customerInput },
      event: ReturnFunnelDescriptionEvents,
    ) => {
      return {
        ...customerInput,
        description: event?.payload?.description,
        needMoreHelp: event.payload?.needMoreHelp,
      }
    },
  }),
}
