<template>
  <div
    class="mb-72 mt-24 md:mb-24"
    data-qa="contact-screen"
    data-test="contact-screen"
  >
    <ContactShell :with-attachments="false" @submit="submitRequest" />

    <RevToast
      :close-alternative-text="
        i18n(translations.errorToastCloseAlternativeTest)
      "
      icon-src="/img/toast/storm.svg"
      name="resolution-flow-return-contact-screen-submition-error-toast"
      :opened="hasSubmitError"
      :title="i18n(translations.errorToastTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="handleCloseErrorToast"
    >
      {{ i18n(translations.errorToastDescription) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevToast } from '@ds/components/Toast'

import type { MachineSend } from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'
import type { ReturnFunnelMachineEvents } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.types'

import type { ContactFormValues } from '../../../../common/ContactShell/ContactShell.types'
import ContactShell from '../../../../common/ContactShell/ContactShell.vue'

import translations from './ContactScreen.translations'
import type { ContactScreenProps } from './ContactScreen.types'

const props = defineProps<ContactScreenProps>()

const i18n = useI18n()

const send = props.machineSend as MachineSend<ReturnFunnelMachineEvents>

const hasSubmitError = computed(() =>
  props.machineState.matches('RETURN_FUNNEL.SUBMISSION_ERROR'),
)

function handleCloseErrorToast() {
  send('BACK')
}

function submitRequest(payload: ContactFormValues) {
  send({
    type: 'CONTINUE',
    payload: {
      description: payload.details,
      needMoreHelp: false,
    },
  })
}
</script>
