<template>
  <RevDialog
    data-test="bundled-services-active-modal"
    :name="RESOLUTION_FLOW_MODALS_NAMES.bundledServicesActiveModal"
    :title="i18n(translations.header)"
  >
    <template #body>
      <h2 class="heading-1">
        {{ i18n(translations.title) }}
      </h2>

      <p class="body-1 mt-12">{{ i18n(translations.text) }}</p>

      <RevButton
        class="mt-32"
        full-width="always"
        variant="primary"
        @click="emit('confirm')"
      >
        {{ i18n(translations.cta1) }}
      </RevButton>

      <RevButton
        class="mb-24 mt-12"
        full-width="always"
        :to="
          resolveLocalizedRoute({
            name: DASHBOARD.MY_ORDERS.SELF,
          })
        "
        variant="secondary"
      >
        {{ i18n(translations.cta2) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'

import { DASHBOARD } from '@/scopes/dashboard/routes'

import { RESOLUTION_FLOW_MODALS_NAMES } from '../names'

import translations from './BundledServicesActiveModal.translations'

const emit = defineEmits(['confirm'])

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
</script>
