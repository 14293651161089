<template>
  <RevDialog
    data-qa="pictures-screen-no-photos-modal"
    data-test="pictures-screen-no-photos-modal"
    :name="CUSTOMER_CARE_MODALS_NAMES.noPhotosModal"
    :title="i18n(translations.noPhotosHeading)"
  >
    <template #body>
      <p class="body-1 mb-12 text-center">
        {{ i18n(translations.noPhotosSuggestion) }}
      </p>
    </template>

    <template #footer>
      <RevButton
        class="mb-12"
        data-qa="add-photos"
        data-test="add-photos"
        full-width="always"
        variant="primary"
        @click="addPhotos"
      >
        {{ i18n(translations.addPhotosNow) }}
      </RevButton>

      <RevButton
        data-qa="no-thanks"
        data-test="no-thanks"
        full-width="always"
        type="button"
        variant="secondary"
        @click="noThanks"
      >
        {{ i18n(translations.noThanks) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { closeModal } from '@ds/components/ModalBase'

import { CUSTOMER_CARE_MODALS_NAMES } from '~/scopes/customer-care/common/modals/names'

import translations from './NoPhotosModal.translations'

const emits = defineEmits(['add-photos', 'no-thanks'])

const i18n = useI18n()

const addPhotos = () => {
  closeModal(CUSTOMER_CARE_MODALS_NAMES.noPhotosModal)
  emits('add-photos')
}

const noThanks = () => {
  closeModal(CUSTOMER_CARE_MODALS_NAMES.noPhotosModal)
  emits('no-thanks')
}
</script>

<style scoped></style>
