export default {
  continueCta: {
    id: 'dashboard_diagnosis_continue',
    defaultMessage: 'Continue',
  },
  faqLink: {
    id: 'dashboard_diagnosis_honesty',
    defaultMessage: 'Honesty is the best policy',
  },
  title: {
    id: 'resolution_flow_out_of_warranty_heading',
    defaultMessage: 'Did any of these things happen?',
  },
  noneOptionValue: {
    id: 'dashboard_diagnosis_out_of_warranty_none',
    defaultMessage: 'None of the above.',
  },
  thirdPartyRepairOptionValue: {
    id: 'dashboard_diagnosis_out_of_warranty_unauthorized_repair',
    defaultMessage: 'Someone other than the refurbisher tried to repair it.',
  },
  waterOrDroppedOptionValue: {
    id: 'dashboard_diagnosis_out_of_warranty_damage',
    defaultMessage:
      'It was exposed to water/humidity or dropped/broken by me or someone else.',
  },
}
