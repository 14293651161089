<template>
  <div data-qa="data-removal-screen" data-test="data-removal-screen">
    <div class="mb-24 mt-32 flex justify-center">
      <RevIllustration
        alt=""
        :height="80"
        sizes="151px"
        src="/img/customer-care/resolution-flow/Return_Product_Animation.gif"
        :width="151"
      />
    </div>

    <h1
      class="heading-1 md:px-0"
      data-qa="title-return-funnel-data-removal-intro"
      data-test="title-return-funnel-data-removal-intro"
    >
      {{ i18n(translations.title) }}
    </h1>

    <p class="body-1 mt-8">{{ i18n(translations.subtitle) }}</p>

    <div class="flex flex-col space-y-16">
      <div class="mt-24 flex flex-row">
        <IconDownload />

        <div class="ml-16 flex-1">
          <p class="body-1">
            {{ i18n(translations.stepBackUpContent) }}
          </p>

          <RevLink
            class="mt-8"
            data-qa="return-funnel-data-removal-intro-backup-link"
            data-test="return-funnel-data-removal-intro-backup-link"
            target="_blank"
            :to="aboutUserAccountArticleLink"
          >
            {{ i18n(translations.stepBackUpLink) }}
          </RevLink>
        </div>
      </div>

      <div class="flex flex-row">
        <IconRefresh />

        <div class="ml-16 flex-1">
          <p class="body-1">
            {{ i18n(translations.stepRemovePersonalDataContent) }}
          </p>

          <RevLink
            class="mt-8"
            data-qa="return-funnel-data-removal-intro-delete-personal-data-link"
            data-test="return-funnel-data-removal-intro-delete-personal-data-link"
            target="_blank"
            :to="aboutUserAccountArticleLink"
          >
            {{ i18n(translations.stepRemovePersonalDataLink) }}
          </RevLink>
        </div>
      </div>
    </div>

    <p class="body-1-bold mt-32">
      {{ i18n(translations.deviceWontTurnOnSectionContent) }}
    </p>

    <RevCheckbox
      id="isDataRemoved"
      v-model="isDataRemoved"
      class="mt-8"
      data-qa="return-funnel-data-removal-intro-data-removed-checkbox"
      data-test="return-funnel-data-removal-intro-data-removed-checkbox"
      variant="full"
    >
      <template #label>
        {{ i18n(translations.confirmCheckboxContent) }}
      </template>
    </RevCheckbox>
    <div class="mb-56 md:mb-72">
      <RevInfoBlock
        v-if="!isDataRemoved && !isInfoBlockDismissed"
        :aria-hidden="isDataRemoved"
        class="mt-32"
        data-qa="something-missing"
        data-test="something-missing"
        dismissable
        :icon="IconArrowUp"
        :title="i18n(translations.confirmInfoBlockTitle)"
        variant="warning"
        @dismiss="onDismiss"
      >
        <span class="body-2 mt-12">
          {{ i18n(translations.confirmInfoBlockContent) }}
        </span>
      </RevInfoBlock>

      <div
        class="w-full"
        :class="isDataRemoved || isInfoBlockDismissed ? 'mt-32 ' : ' mt-12'"
      >
        <RevButton
          data-qa="continue"
          data-test="continue"
          :disabled="!isDataRemoved"
          full-width="always"
          variant="primary"
          @click="onContinue"
        >
          {{ i18n(translations.continue) }}
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { ref } from 'vue'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { IconArrowUp } from '@ds/icons/IconArrowUp'
import { IconDownload } from '@ds/icons/IconDownload'
import { IconRefresh } from '@ds/icons/IconRefresh'
import {
  RevButton,
  RevCheckbox,
  RevIllustration,
  RevInfoBlock,
  RevLink,
} from '@ds/main'

import { isConfigDefined } from '~/scopes/care-commons/utils/isConfigDefined'

import translations from './DataRemovalScreen.translations'
import type { DataRemovalScreenProps } from './DataRemovalScreen.types'

const props = defineProps<DataRemovalScreenProps>()
const i18n = useI18n()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()
const { ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID } = useRuntimeConfig().public

const isDataRemoved = ref(false)
const isInfoBlockDismissed = ref(false)
const aboutUserAccountArticleLink = toNuxtLink({
  type: 'external',
  href: `${zendeskFaqBaseUrl}/articles/${isConfigDefined(ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID, 'ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID')}`,
})

const onContinue = () => {
  props.machineSend({
    type: 'CONTINUE',
    payload: {
      areDataRemoved: isDataRemoved.value,
    },
  })
}

const onDismiss = () => {
  isInfoBlockDismissed.value = true
}
</script>
