export default {
  errorToastCloseAlternativeTest: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  errorToastDescription: {
    id: 'dashboard_diagnosis_contact_form_error_message',
    defaultMessage:
      'We couldn’t send your message. Check your network connection and try again.',
  },
  errorToastTitle: {
    id: 'dashboard_diagnosis_contact_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
  infoBlockDescription: {
    id: 'dashboard_diagnosis_contact_form_out_of_warranty_description',
    defaultMessage:
      'The refurbisher may be able to help you resolve an issue for an extra fee – but if your item was damaged or someone other than the refurbisher tried to repair it, it is no longer covered by the warranty.',
  },
  infoBlockLink: {
    id: 'dashboard_diagnosis_contact_form_out_of_warranty_link',
    defaultMessage: 'More about the warranty',
  },
  infoBlockTitle: {
    id: 'dashboard_diagnosis_contact_form_out_of_warranty_title',
    defaultMessage: 'Out of warranty',
  },
  title: {
    id: 'dashboard_diagnosis_contact_heading',
    defaultMessage: 'Describe your issue',
  },
}
