<template>
  <div>
    <h2 class="heading-1 mb-24">{{ title }}</h2>

    <div v-if="$slots.content" class="mb-24">
      <slot name="content" />
    </div>

    <p class="body-1 mb-12">{{ i18n(translations.formIntroduction) }}</p>
    <RevForm
      :initial-values="contactInitialFormValues"
      :name="CONTACT_FORM_NAME"
      @submit="handleContactFormSubmit"
    >
      <template #default="{ hasError, values }">
        <RevInputTextarea
          id="contact-details"
          v-model="values.details"
          data-qa="resolution-flow-contact-details"
          data-test="resolution-flow-contact-details"
          :label="i18n(translations.inputDescriptionLabel)"
        />

        <div class="mb-56 mt-12">
          <InputFileUpload v-if="withAttachments" v-model="values.files" />
        </div>

        <div>
          <p v-if="withfooterCaption" class="caption mb-12">
            {{ footerCaptionText }}
          </p>

          <div class="flex flex-col items-center gap-y-12">
            <RevButton
              data-qa="contact-refurbisher"
              :disabled="hasError"
              full-width="always"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submitButton) }}
            </RevButton>

            <RevButton
              v-if="secondaryCtaLabel"
              full-width="always"
              :to="secondaryCtaTo"
              variant="secondary"
              @click="secondaryCtaOnClick"
            >
              {{ secondaryCtaLabel }}
            </RevButton>
          </div>
        </div>
      </template>
    </RevForm>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevForm } from '@ds/components/Form'
import { RevInputTextarea } from '@ds/components/InputTextarea'

import InputFileUpload from '../InputFileUpload/InputFileUpload.vue'

import { CONTACT_FORM_NAME } from './ContactShell.constants'
import translations from './ContactShell.translations'
import type { ContactFormValues, ContactShellProps } from './ContactShell.types'

const emit = defineEmits<{
  submit: [ContactFormValues]
}>()

const i18n = useI18n()

const props = withDefaults(defineProps<ContactShellProps>(), {
  withAttachments: true,
  withfooterCaption: true,
})

const contactInitialFormValues = ref<ContactFormValues>({
  details: '',
  files: [],
})

const footerCaptionText = computed(() =>
  props.footerCaptionText
    ? props.footerCaptionText
    : i18n(translations.expectedAnswer),
)

const title = computed(() =>
  props.title ? props.title : i18n(translations.defaultTitle),
)

function handleContactFormSubmit(payload: ContactFormValues) {
  emit('submit', payload)
}
</script>
