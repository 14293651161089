import { defineAsyncComponent } from 'vue'

import {
  type GetResolutionOptionsTypeEnum,
  RESOLUTION_OPTION_TYPE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

export type ResolutionOptionsConfig = Partial<
  Record<GetResolutionOptionsTypeEnum, ReturnType<typeof defineAsyncComponent>>
>

export const resolutionOptionsConfig: ResolutionOptionsConfig = {
  [RESOLUTION_OPTION_TYPE_ENUM.chat]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/ChatResolutionOptionCard/ChatResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.liveChat]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/LiveChatResolutionOptionCard/LiveChatResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.refund]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/RefundResolutionOptionCard/RefundResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/RemoteAssistanceResolutionOptionCard/RemoteAssistanceResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.repairReplace]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/RepairReplaceResolutionOptionCard/RepairReplaceResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.sellerRepair]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/SellerRepairResolutionOptionCard/SellerRepairResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.straightRefund]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/StraightRefundResolutionOptionCard/StraightRefundResolutionOptionCard.vue'
      ),
  ),
  [RESOLUTION_OPTION_TYPE_ENUM.expressReplace]: defineAsyncComponent(
    () =>
      import(
        '@/scopes/care-commons/components/resolutionEngine/ExpressReplaceResolutionOptionCard/ExpressReplaceResolutionOptionCard.vue'
      ),
  ),
}
