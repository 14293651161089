export default {
  errorToastCloseAlternativeTest: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  errorToastDescription: {
    id: 'dashboard_diagnosis_contact_form_error_message',
    defaultMessage:
      'We couldn’t send your message. Check your network connection and try again.',
  },
  errorToastTitle: {
    id: 'dashboard_diagnosis_contact_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
}
