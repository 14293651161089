import { RESOLUTION_OPTIONS_ACTIONS_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { type TransitionsConfig, assign } from 'xstate'

import type {
  MachineContext,
  MachineEvents,
} from '~/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'

export const ReturnFunnelTransitions: TransitionsConfig<
  MachineContext,
  MachineEvents
> = {
  BACK: {
    actions: 'goToPreviousPage',
  },
  EXIT_RETURN_FUNNEL: {
    actions: assign({
      activeAction: null,
    }),
    target: '#RESOLUTION_OPTION',
  },
  GO_TO_SHIPMENT: {
    actions: assign({
      activeAction: RESOLUTION_OPTIONS_ACTIONS_ENUM.createShipment,
    }),
    target: '.CREATING_SHIPMENT',
  },
  GO_TO_DATA_REMOVAL: {
    actions: assign({
      activeAction: RESOLUTION_OPTIONS_ACTIONS_ENUM.showDataRemoval,
    }),
    target: '.DATA_REMOVAL_INTRO',
  },
  GO_TO_PICTURES_INTRO: {
    actions: assign({
      activeAction: RESOLUTION_OPTIONS_ACTIONS_ENUM.showPicturesWithIntro,
    }),
    target: '.PICTURES_INTRO',
  },
  GO_TO_PICTURES: {
    actions: assign({
      activeAction: RESOLUTION_OPTIONS_ACTIONS_ENUM.showPictures,
    }),
    target: '.PICTURES',
  },
  GO_TO_DESCRIPTION: {
    actions: assign({
      activeAction: RESOLUTION_OPTIONS_ACTIONS_ENUM.showMessage,
    }),
    target: '.DESCRIPTION',
  },
  SEND_REQUEST: {
    target: '.INITIATING_RESOLUTION',
  },
}
