<template>
  <div class="contents">
    <ContactShell @submit="submitRequest" />

    <RevToast
      :close-alternative-text="
        i18n(translations.errorToastCloseAlternativeTest)
      "
      icon-src="/img/toast/storm.svg"
      name="resolution-flow-remote-assistance-contact-screen-submition-error-toast"
      :opened="hasSubmitError"
      :title="i18n(translations.errorToastTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="handleCloseErrorToast"
    >
      {{ i18n(translations.errorToastDescription) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevToast } from '@ds/components/Toast'

import type {
  ContactEvents,
  MachineSend,
} from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'

import type { ContactFormValues } from '../../../common/ContactShell/ContactShell.types'
import ContactShell from '../../../common/ContactShell/ContactShell.vue'

import translations from './RemoteAssistanceContactScreen.translations'
import type { RemoteAssistanceContactScreenProps } from './RemoteAssistanceContactScreen.types'

const props = defineProps<RemoteAssistanceContactScreenProps>()

const i18n = useI18n()

const send = props.machineSend as MachineSend<ContactEvents>

const hasSubmitError = computed(() =>
  props.machineState.matches('CONTACT.SUBMISSION_ERROR'),
)

function handleCloseErrorToast() {
  send('GO_TO_FORM')
}

function submitRequest(payload: ContactFormValues) {
  send({
    type: 'CONTACT_REFURBISHER',
    payload: {
      description: payload.details,
      pictures: payload.files,
    },
  })
}
</script>
