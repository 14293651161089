<template>
  <div
    class="contents"
    data-qa="resolution-flow-orderline-lifecycle"
    data-test="resolution-flow-orderline-lifecycle"
  >
    <div v-if="shouldDisplayOrderlineTimelineVariantInfos">
      <h1
        class="heading-1 mt-16 md:mt-24"
        data-qa="title-informative-screen"
        data-test="title-informative-screen"
      >
        {{ i18n(wording.heading) }}
      </h1>

      <RevInfoBlock
        class="mt-24 md:mt-32"
        :icon="IconInfo"
        :title="i18n(wording.statusStatsInfoBlock)"
        variant="info"
      />

      <p class="body-1 mt-16">
        <FormattedMessage :definition="wording.informativeStatus">
          <template #duration>
            <span class="body-1-bold">{{
              wording.informativeStatusDuration
            }}</span>
          </template>
        </FormattedMessage>
      </p>
    </div>

    <RevCard class="mt-32 p-24">
      <OrderlineTimeline
        class="mt-16"
        :timeline="
          machineState.context.orderlineTimeline as OrderlineTimelineResponse
        "
      />
    </RevCard>

    <Transition
      enter-active-class="transition-opacity transition-transform ease-out translate-y-0 opacity-100"
      enter-class="-translate-y-4 opacity-0"
      leave-active-class="transition-opacity transition-transform ease-out translate-y-0 opacity-100"
      leave-to-class="translate-y-4 opacity-0"
      mode="out-in"
    >
      <section v-if="!easterEggSection" key="ctaSection" class="mb-72 mt-24">
        <p class="body-1-bold mb-12">
          {{ i18n(translations.stillHaveQuestions) }}
        </p>

        <div class="flex flex-col">
          <RevButton
            v-if="isWithdrawal"
            class="mb-16"
            data-qa="cancel-order"
            data-test="cancel-order"
            variant="secondary"
            @click="navigateToResolutionExperience"
          >
            {{ i18n(translations.cancelOrderCta) }}
          </RevButton>

          <RevButton
            v-else
            class="mb-16"
            data-qa="contact-seller"
            data-test="contact-seller"
            variant="secondary"
            @click="contactSeller"
          >
            {{ i18n(translations.yesContactSeller) }}
          </RevButton>

          <RevButton
            data-qa="no-its-all-good"
            data-test="no-its-all-good"
            variant="secondary"
            @click="displayEasterEgg"
          >
            {{ i18n(translations.noItsAllGood) }}
          </RevButton>
        </div>
      </section>

      <section v-else key="easterEggSection" class="mb-72 mt-24">
        <div class="flex flex-col">
          <RevInfoBlock
            class="mb-12"
            :icon="IconCupcake"
            :title="i18n(translations.easterEggHeader)"
            variant="success"
          >
            <p class="body-2 mt-4">
              <FormattedMessage :definition="translations.easterEggMessage">
                <template #link>
                  <RevLink
                    data-qa="easter-egg-link"
                    data-test="easter-egg-link"
                    target="_blank"
                    to="https://www.boredpanda.com/cute-baby-animals/"
                  >
                    {{ i18n(translations.solidReadingMaterial) }}</RevLink
                  >
                </template>
              </FormattedMessage>
            </p>
          </RevInfoBlock>

          <RevButton
            data-qa="return-to-my-orders"
            data-test="return-to-my-orders"
            :to="myOrdersRoute"
            variant="secondary"
          >
            {{ i18n(translations.backToMyOrders) }}
          </RevButton>
        </div>
      </section>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { computed, ref } from 'vue'

import type { OrderlineTimelineResponse } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCupcake } from '@ds/icons/IconCupcake'
import { IconInfo } from '@ds/icons/IconInfo'
import { RevButton, RevCard, RevInfoBlock, RevLink } from '@ds/main'

import OrderlineTimeline from '@/scopes/care-commons/components/order/OrderlineTimeline/OrderlineTimeline.vue'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import {
  content,
  orderlineLifecycleMatchesStates,
} from './OrderlineLifeCycleScreen.config'
import translations from './OrderlineLifeCycleScreen.translations'
import type { OrderLineLifeCycleScreenProps } from './OrderlineLifeCycleScreen.types'
import { getInformativeStatusDuration } from './utils/getInformativeStatusDuration'

const props = defineProps<OrderLineLifeCycleScreenProps>()
const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const easterEggSection = ref(false)

const activeState = orderlineLifecycleMatchesStates.filter(
  props.machineState.matches,
)[0]

const myOrdersRoute = resolveLocalizedRoute({
  name: DASHBOARD.MY_ORDERS.SELF,
})

const displayEasterEgg = () => {
  easterEggSection.value = true
}
const contactSeller = () => props.machineSend('CONTACT_SELLER')
const navigateToResolutionExperience = () =>
  props.machineSend('NAVIGATE_TO_RESOLUTION_EXPERIENCE')
const wording = computed(() => {
  const { informativeStatusDuration, ...currentContent } = content[activeState]

  return {
    ...currentContent,
    informativeStatusDuration: getInformativeStatusDuration(
      props.machineState.context.orderlineTimeline,
      informativeStatusDuration.step,
      informativeStatusDuration.isDayFormat,
      informativeStatusDuration.translation,
    ),
  }
})
const isWithdrawal = computed(() =>
  props.machineState.context.diagnosisV2CustomerIssuesTags.includes(
    'WITHDRAWAL',
  ),
)
const shouldDisplayOrderlineTimelineVariantInfos = computed(
  () => !!content[activeState],
)
</script>

<style scoped></style>
