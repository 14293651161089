export default {
  header: {
    id: 'resolution_flow_resolution_option_straight_refund',
    defaultMessage: 'Get a refund',
  },
  title: {
    id: 'dashboard_diagnosis_modal_bundled_services_title',
    defaultMessage: 'Bundled services stay active.',
  },
  text: {
    id: 'dashboard_diagnosis_modal_bundled_services_text',
    defaultMessage:
      'This means you’ll still be billed for bundled services after returning your item. Check your provider’s website for more information.',
  },
  cta1: {
    id: 'dashboard_diagnosis_modal_bundled_services_cta_continue',
    defaultMessage: 'Continue',
  },
  cta2: {
    id: 'dashboard_diagnosis_modal_bundled_services_cta_my_orders',
    defaultMessage: 'Go to My Orders',
  },
  closeAlt: {
    id: 'diagnosis_customer_issue_imei_modal_close_alt_text',
    defaultMessage: 'Close',
  },
}
