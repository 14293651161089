<template>
  <div class="contents">
    <h2 class="heading-1 mb-12">{{ i18n(translations.title) }}</h2>

    <RevLink
      class="text-action-default-low mb-24 flex items-center space-x-4"
      target="_blank"
      :to="warrantyFaqArticleLink"
    >
      <IconDiamond size="24" />
      <p class="body-1-bold">{{ i18n(translations.faqLink) }}</p>
    </RevLink>

    <div class="mb-24 space-y-12">
      <RevRadio
        v-for="option in warrantyStateOptions"
        :id="option.id"
        :key="option.id"
        v-model="warrantyState"
        :data-qa="option.dataQa"
        :value="option.value"
        variant="full"
      >
        <template #label>{{ i18n(option.label) }}</template>
      </RevRadio>
    </div>

    <RevButton
      data-qa="continue"
      :disabled="!hasSelectedReason"
      full-width="always"
      variant="primary"
      @click="onContinue"
    >
      {{ i18n(translations.continueCta) }}
    </RevButton>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type { GetDeclaredWarrantyStateEnum } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevLink } from '@ds/components/Link'
import { RevRadio } from '@ds/components/Radio'
import { IconDiamond } from '@ds/icons/IconDiamond'

import { buildFaqArticleUrl } from '@/scopes/customer-care/common/utils/faqArticles'
import type {
  MachineSend,
  OutOfWarrantyEvents,
} from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'

import { WARRANTY_FAQ_ARTICLE } from '../../../ResolutionFlowScreens.constants'

import { warrantyStateOptions } from './WarrantyExclusionScree.constants'
import translations from './WarrantyExclusionScreen.translations'
import type { WarrantyExclusionScreenProps } from './WarrantyExclusionScreen.types'

const props = defineProps<WarrantyExclusionScreenProps>()

const warrantyState = ref<GetDeclaredWarrantyStateEnum | boolean>(false)

const i18n = useI18n()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const send = props.machineSend as MachineSend<OutOfWarrantyEvents>

const warrantyFaqArticleLink = buildFaqArticleUrl({
  rootUrl: zendeskFaqBaseUrl,
  article: WARRANTY_FAQ_ARTICLE,
})

const hasSelectedReason = computed(() => !isEmpty(warrantyState.value))

function onContinue() {
  send({
    type: 'SUBMIT',
    payload: {
      declaredWarrantyState:
        warrantyState.value as GetDeclaredWarrantyStateEnum,
    },
  })
}
</script>
