import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import type {
  MachineContext,
  MachineStateSchema,
} from '../../../../../machine/ResolutionFlow.machine.types'

import translations from './OrderlineLifecycleContactScreen.translations'

type OrderlineLifecycleStates = Extract<
  MachineStateSchema['matchesStates'],
  | 'CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM'
  | 'CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM'
  | 'CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM'
>

type InfoBlockContent = {
  title: I18nDefinition
  date?: string | Date | null
}

export type OrderlineLifecycleContactInfoBlockConfig = Partial<
  Record<OrderlineLifecycleStates, InfoBlockContent>
>

const getActiveStepTime = (
  orderlineTimeline: MachineContext['orderlineTimeline'],
  timelineStep: StepType,
) => {
  const deliveryDate = orderlineTimeline?.find(
    (step) => step.type === timelineStep,
  )?.date

  return deliveryDate ? new Date(deliveryDate) : null
}

export const orderlineLifecycleMatchesStates: OrderlineLifecycleStates[] = [
  'CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM',
  'CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM',
  'CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM',
]

export const getInfoBlockContentConfig = (
  orderlineTimeline: MachineContext['orderlineTimeline'],
): OrderlineLifecycleContactInfoBlockConfig => {
  return {
    'CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM': {
      title: translations.notValidatedInfoBlockContent,
      date: '24h',
    },
    'CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM': {
      title: translations.notShippedInfoBlockContent,
      date: getActiveStepTime(
        orderlineTimeline,
        ORDERLINE_TIMELINE_STEP_TYPE.shipment,
      ),
    },
    'CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM': {
      title: translations.notDeliveredInfoBlockContent,
      date: getActiveStepTime(
        orderlineTimeline,
        ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ),
    },
  }
}
