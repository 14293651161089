import type { GetResolutionOptionsActionsEnum } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export function getSelectedResolution(context: MachineContext) {
  return context.customerInput.resolutionOptions?.[0] ?? null
}

export function getSelectedResolutionActions(
  context: MachineContext,
): GetResolutionOptionsActionsEnum[] {
  const selectedResolution = getSelectedResolution(context)

  return selectedResolution?.actions || []
}
