<template>
  <div class="contents">
    <ContactShell @submit="submitRequest">
      <template #content>
        <RevInfoBlock
          :content="i18n(translations.infoBlockDescription)"
          :icon="IconWarning"
          :title="i18n(translations.infoBlockTitle)"
          variant="warning"
        >
          <div class="mt-12">
            <RevLink
              data-qa="faq-link"
              target="_blank"
              :to="warrantyFaqArticleLink"
            >
              {{ i18n(translations.infoBlockLink) }}
            </RevLink>
          </div>
        </RevInfoBlock>
      </template>
    </ContactShell>

    <RevToast
      :close-alternative-text="
        i18n(translations.errorToastCloseAlternativeTest)
      "
      icon-src="/img/toast/storm.svg"
      name="resolution-flow-remote-assistance-contact-screen-submition-error-toast"
      :opened="hasSubmitError"
      :title="i18n(translations.errorToastTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="handleCloseErrorToast"
    >
      {{ i18n(translations.errorToastDescription) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { RevToast } from '@ds/components/Toast'
import { IconWarning } from '@ds/icons/IconWarning'

import { buildFaqArticleUrl } from '@/scopes/customer-care/common/utils/faqArticles'
import type {
  ContactEvents,
  MachineSend,
} from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'

import type { ContactFormValues } from '../../../../common/ContactShell/ContactShell.types'
import ContactShell from '../../../../common/ContactShell/ContactShell.vue'
import { WARRANTY_FAQ_ARTICLE } from '../../../ResolutionFlowScreens.constants'

import translations from './WarrantyExclusionContactScreen.translations'
import type { WarrantyExclusionContactScreenProps } from './WarrantyExclusionContactScreen.types'

const props = defineProps<WarrantyExclusionContactScreenProps>()

const i18n = useI18n()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const send = props.machineSend as MachineSend<ContactEvents>

const warrantyFaqArticleLink = buildFaqArticleUrl({
  rootUrl: zendeskFaqBaseUrl,
  article: WARRANTY_FAQ_ARTICLE,
})

const hasSubmitError = computed(() =>
  props.machineState.matches('CONTACT.SUBMISSION_ERROR'),
)

function handleCloseErrorToast() {
  send('GO_TO_FORM')
}

function submitRequest(payload: ContactFormValues) {
  send({
    type: 'CONTACT_REFURBISHER',
    payload: {
      description: payload.details,
      pictures: payload.files,
    },
  })
}
</script>
