import {
  type GetResolutionOptionsActionsEnum,
  RESOLUTION_OPTIONS_ACTIONS_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

import type { ReturnFunnelMachineEvents } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.types'

export const ReturnFunnelResolutionActions = [
  RESOLUTION_OPTIONS_ACTIONS_ENUM.createShipment,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showDataRemoval,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showPictures,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showPicturesWithIntro,
  RESOLUTION_OPTIONS_ACTIONS_ENUM.showMessage,
] as const

/**
 * Map between an action from the `actions` property on the resolution
 * to the corresponding event to emit. E.g. we should go to the
 * data removal screen for the data removal action.
 */
export const ResolutionActionToEvent: Record<
  GetResolutionOptionsActionsEnum,
  ReturnFunnelMachineEvents['type']
> = {
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.createShipment]: 'GO_TO_SHIPMENT',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showDataRemoval]: 'GO_TO_DATA_REMOVAL',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showPictures]: 'GO_TO_PICTURES',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showPicturesWithIntro]:
    'GO_TO_PICTURES_INTRO',
  [RESOLUTION_OPTIONS_ACTIONS_ENUM.showMessage]: 'GO_TO_DESCRIPTION',
}
