<template>
  <div class="contents">
    <section class="mt-32 md:mt-24">
      <h1 class="heading-1">{{ i18n(translations.title) }}</h1>

      <p class="body-1 mt-8">{{ i18n(translations.subtitle) }}</p>

      <RevButton
        class="mt-24"
        data-qa="self-troubleshooting-continue-cta"
        data-test="self-troubleshooting-continue-cta"
        full-width="always"
        variant="primary"
        @click="onContinue"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </section>

    <RevDivider class="mb-24 mt-32" />

    <section class="mb-72 md:mb-0">
      <h2 class="heading-2 mb-12">
        {{ i18n(translations.articleListTitle) }}
      </h2>

      <RevList
        v-if="selfTroubleshootingArticles"
        :has-dividers="true"
        :has-external-borders="false"
      >
        <RevListItemInteractive
          v-for="article in selfTroubleshootingArticles"
          :key="article.id"
          data-qa="self-troubleshooting-article"
          data-test="self-troubleshooting-article"
          :to="formatUrl(article.url)"
          @click="onArticleClick(article.id)"
        >
          <template #label>{{ article.label }} </template>

          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemInteractive>
      </RevList>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { uniqBy } from '@backmarket/utils/collection/uniqBy'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import {
  RevButton,
  RevDivider,
  RevList,
  RevListItemInteractive,
} from '@ds/main'

import translations from './SelfTroubleshootingScreen.translations'
import type { SelfTroubleShootingProps } from './SelfTroubleshootingScreen.types'

const props = defineProps<SelfTroubleShootingProps>()

const i18n = useI18n()

const selfTroubleshootingArticles = computed(() => {
  if (isEmpty(props.articles)) {
    return null
  }

  const articleList = props.articles.map((item) => item.articles).flat()

  return uniqBy(articleList, 'id')
})

const onContinue = () => props.machineSend('CONTINUE')

const onArticleClick = (articleId: number) =>
  props.machineSend({ type: 'CLICK_ARTICLE', articleId })

const formatUrl = (url: string) => {
  const formatedUrl = new URL(url)
  formatedUrl.searchParams.set('self-troubleshooting', '')

  return formatedUrl.href
}
</script>
