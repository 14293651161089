<template>
  <RevInfoBlock
    :content="i18n(content)"
    :icon="IconWarning"
    :title="i18n(title)"
    variant="warning"
  />
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import translations from './BundleInfo.translations'
import type { BundleInfoProps } from './BundleInfo.types'

const props = defineProps<BundleInfoProps>()

const i18n = useI18n()

const title = props.cancellableBundle
  ? translations.title1
  : translations.title2
const content = props.cancellableBundle
  ? translations.text1
  : translations.text2
</script>
