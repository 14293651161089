import { handleFileUpload } from '@backmarket/front-upload'
import type { UploadEntry } from '@backmarket/front-upload/dist/types/types'
import { initiateResolution as initiateResolutionQuery } from '@backmarket/http-api/src/api-specs-resolution-engine/endpoints'
import {
  RESOLUTION_ENGINE_SERVICE_ENUM,
  RESOLUTION_OPTION_TYPE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import type { InitiateResolutionPayload } from '@backmarket/http-api/src/api-specs-resolution-engine/types/initiateResolution'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { getHttpBaseUrl } from '@backmarket/nuxt-module-http/getHttpBaseUrl'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { get } from '@backmarket/utils/object/get'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'
import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'

import type { MachineContext } from '../ResolutionFlow.machine.types'

export class UploadFailedError extends Error {
  constructor(message = 'Upload failed') {
    super(message)
    this.name = 'UploadFailedError'
  }
}

async function uploadAttachment(
  businessRule: string,
  serviceContext: Record<string, unknown>,
  file: UploadEntry | File,
) {
  const uploadStartTime = Date.now()

  const logger = useLogger()

  logger.info(`${businessRule} upload: Start`, {
    step: `upload-${businessRule}-start`,
    subService: 'front-upload',
    timestamp: uploadStartTime,
    uploadFile: file,
    owners: ['bot-squad-sales-customer-care-front'],
  })

  let uploadedFile
  try {
    uploadedFile = await handleFileUpload(
      `${getHttpBaseUrl()}/services/uploads`,
      businessRule,
      serviceContext,
      file,
    )
  } catch (error) {
    throw new UploadFailedError()
  }

  switch (uploadedFile.status) {
    case 'success': {
      const endTime = Date.now()

      logger.info(`${businessRule} upload: Done`, {
        elapsedTime: endTime - uploadStartTime,
        step: `upload-${businessRule}-done`,
        subService: 'front-upload',
        timestamp: endTime,
        uploadFile: file,
        uploadEntry: uploadedFile,
        owners: ['bot-squad-sales-customer-care-front'],
      })

      return uploadedFile.handle
    }

    case 'failed':
    default:
      throw new UploadFailedError()
  }
}

export async function initiateResolution({
  customerInput,
  diagnosisV2CustomerIssues,
  orderlineId,
  shipment,
}: MachineContext) {
  const { logFeatureError } = useSalesCustomerCareLogger()

  const { user } = useUserStore()

  const attachmentsUploadPromises = customerInput?.pictures?.map((file) =>
    uploadAttachment(
      'care_resolution_attachment',
      { customerId: user?.clientId?.toString() },
      file,
    ),
  )

  const attachmentHandles = attachmentsUploadPromises
    ? await Promise.all(attachmentsUploadPromises)
    : []

  /**
   * `customerInput.resolutionOptions[0]` contains the resolution option
   * selected by the customer. We can safely pick the first item of the array.
   *
   * Furthermore, `customerInput` is an array because at the end of the Return Funnel,
   * The customer can currently ask to be contacted by the seller,
   * which adds a REMOTE_ASSISTANCE resolution option to that array.
   */
  const selectedResolutionOption = customerInput?.resolutionOptions?.[0] || {
    serviceName: RESOLUTION_ENGINE_SERVICE_ENUM.afterSalesPlatform,
    type: RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance,
    actions: [],
  }

  const resolutionRequest: InitiateResolutionPayload['resolutionRequest'] = {
    attachmentHandles,
    isDataRemovalConfirmed: customerInput.areDataRemoved || false,
    message: customerInput.description,
  }

  if (!isEmpty(shipment)) {
    resolutionRequest.shipmentId = shipment?.id
  }

  const payload = await $httpFetch(initiateResolutionQuery, {
    body: {
      declaredProblem: {
        declaredWarrantyState: customerInput.declaredWarrantyState,
        orderlineId,
        customerIssues: diagnosisV2CustomerIssues,
      },
      resolutionOption: selectedResolutionOption,
      resolutionRequest,
    },
  })

  const resolution = get(payload, 'resolution')

  if (!isEmpty(resolution)) {
    return { resolution }
  }

  logFeatureError({
    errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.resolutionFlow} no resolution initiated on initiate resolution service`,
    errorDetail: {
      requestBody: {
        declaredProblem: {
          declaredWarrantyState: customerInput.declaredWarrantyState,
          orderlineId,
          customerIssues: diagnosisV2CustomerIssues,
        },
        resolutionOption: selectedResolutionOption,
        resolutionRequest,
      },
    },
    featureName: 'resolution_flow_no_resolution_initiated',
  })

  throw new Error('No resolution initiated')
}
