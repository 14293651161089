<template>
  <div class="contents">
    <div class="mb-20">
      <div
        v-if="isDetailsPage"
        class="flex text-center"
        data-test="express-replacement-breadcrumb"
      >
        <IconArrowLeft
          class="flex-none shrink-0 cursor-pointer"
          data-test="back-button"
          @click="back"
        />
        <span class="body-1 flex-1 grow">{{
          i18n(translations.breadCrumbTitle)
        }}</span>
      </div>
      <RevStepper
        v-else
        :active-step="route.name as string"
        :alternative-text-back="i18n(translations.stepperAltTextBack)"
        :alternative-text-close="i18n(translations.stepperAltTextClose)"
        :alternative-text-completed="i18n(translations.stepperAltTextCompleted)"
        :alternative-text-current="i18n(translations.stepperAltTextCurrent)"
        data-test="express-replacement-stepper"
        has-back
        has-close
        :steps
        @back="back"
        @close="close"
      />
    </div>
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, inject, onUnmounted } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { openModal } from '@ds/components/ModalBase'
import { RevStepper } from '@ds/components/Stepper'
import type { Step } from '@ds/components/Stepper/Stepper.constant'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import { CUSTOMER_CARE_MODALS_NAMES } from '~/scopes/customer-care/common/modals/names'
import { ResolutionFlowStateMachineKey } from '~/scopes/customer-care/resolution/symbols'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import translations from './ExpressReplacementScreen.translations'

const route = useRoute()
const { back } = useRouter()

const i18n = useI18n()

const resolutionFlowStateMachine = inject(ResolutionFlowStateMachineKey)

const isDetailsPage = computed(
  () => route.name === CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.DETAILS,
)

const steps: Step[] = [
  {
    name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.SHIPPING,
    label: i18n(translations.stepperShipping),
  },
  {
    name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.DATA_REMOVAL,
    label: i18n(translations.stepperDataRemoval),
  },
  {
    name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.PICTURES,
    label: i18n(translations.stepperPictures),
  },
  {
    name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.CONTACT,
    label: i18n(translations.stepperContact),
  },
  {
    name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.PAYMENT,
    label: i18n(translations.stepperPayment),
  },
]

const close = () => openModal(CUSTOMER_CARE_MODALS_NAMES.cancellationModal)

onUnmounted(() => {
  if (!resolutionFlowStateMachine?.state.value.hasTag('loading_failed')) {
    resolutionFlowStateMachine?.send('BACK')
  }
})
</script>
