export default {
  continue: {
    id: 'dashboard_diagnosis_continue',
    defaultMessage: 'Continue',
  },
  errorToastCloseAlternativeTest: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  errorToastDescription: {
    id: 'resolution_flow_error_toast_description',
    defaultMessage: 'Please give it a moment and try again later.',
  },
  errorToastTitle: {
    id: 'resolution_flow_error_toast_title',
    defaultMessage: 'Something went wrong',
  },
  heading: {
    id: 'dashboard_diagnosis_option_heading',
    defaultMessage: "Ok, here's what you can do",
  },
  selectAnOption: {
    id: 'dashboard_diagnosis_select_an_option',
    defaultMessage: 'Select an option',
  },
  getRemotAssistance: {
    id: 'diagnosis_get_remote_assistance_text',
    defaultMessage:
      'Need help deciding between repair/replace or refund (if within 30 days of purchase)? Back Market or the seller can email you to help you decide which of these options is best for you.',
  },
  helpByEmail: {
    id: 'diagnosis_get_remote_assistance_cta',
    defaultMessage: 'Request help by email',
  },
  repairUnavailableInfoText: {
    id: 'resolution_flow_unavailable_repair_info_text',
    defaultMessage:
      'Why don’t I see an option to repair or replace this device?',
  },
  repairUnavailableTooltipContent: {
    id: 'resolution_flow_unavailable_repair_tooltip_content',
    defaultMessage:
      'This seller doesn’t currently offer device repair or replacement.',
  },
}
