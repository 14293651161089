import { isEmpty } from '@backmarket/utils/object/isEmpty'

interface buildFaqArticleUrlParams {
  rootUrl: string
  article: {
    id: string
    anchor?: string
  }
}

export function buildFaqArticleUrl({
  rootUrl,
  article,
}: buildFaqArticleUrlParams) {
  const faqArticleUrl = new URL(rootUrl)

  faqArticleUrl.pathname += `/articles/${article.id}`

  if (!isEmpty(article.anchor)) {
    faqArticleUrl.pathname += `#${article.anchor}`
  }

  return faqArticleUrl.toString()
}
