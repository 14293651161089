<template>
  <div
    class="contents"
    data-qa="return-pictures-screen"
    data-test="return-pictures-screen"
  >
    <div v-if="isIntroScreen" class="mt-32 pb-20">
      <div class="mb-7 flex justify-center">
        <RevIllustration
          alt=""
          :height="80"
          sizes="151px"
          src="/img/customer-care/resolution-flow/Return_Product_Animation.gif"
          :width="151"
        />
      </div>

      <h1 class="heading-1 mb-16" data-qa="title-return-funnel-pictures-intro">
        {{ i18n(translations.prepareYourReturn) }}
      </h1>

      <p class="body-1">
        {{ i18n(translations.followTheseSteps) }}
      </p>
    </div>

    <component
      :is="isIntroScreen ? 'h2' : 'h1'"
      class="mb-8 md:mb-4"
      :class="isIntroScreen ? 'heading-2' : 'heading-1 mt-24'"
      data-qa="title-return-funnel-pictures"
    >
      {{ i18n(translations.heading) }}
    </component>

    <p class="body-1 mb-16 md:mb-24">
      {{ i18n(translations.photoSuggestion) }}
    </p>

    <form class="mb-72" @submit.prevent="onSubmit">
      <InputFileUpload id="pictures" ref="inputFile" v-model="pictures" />

      <p class="body-1-bold mb-12 mt-32 md:mt-24">
        {{ i18n(translations.whatWeNeed) }}
      </p>

      <ul class="mb-12 list-inside list-disc space-y-16">
        <li class="body-1">
          {{ i18n(translations.twoScreenPhotos) }}
        </li>

        <li class="body-1">
          {{ i18n(translations.oneBackPhoto) }}
        </li>

        <li class="body-1">
          {{ i18n(translations.onePhotoPerSide) }}
        </li>
      </ul>

      <RevLink
        data-qa="see-examples"
        data-test="see-examples"
        target="_blank"
        :to="examplesFaqArticleLink"
      >
        {{ i18n(translations.seeExamples) }}
      </RevLink>

      <div class="flex flex-col justify-between">
        <RevButton
          class="mt-24 md:mt-32"
          data-qa="continue"
          data-test="continue"
          type="submit"
          :variant="continueCta.variant"
        >
          {{ continueCta.label }}
        </RevButton>
      </div>
    </form>

    <NoPhotosModal
      @add-photos="onTriggerInputFile"
      @no-thanks="onConfirmSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { VariantsEnum as ButtonVariants } from '@ds/components/Button/Button.constant'
import { openModal } from '@ds/components/ModalBase'
import { RevButton, RevIllustration, RevLink } from '@ds/main'

import { isConfigDefined } from '~/scopes/care-commons/utils/isConfigDefined'
import NoPhotosModal from '~/scopes/customer-care/common/modals/NoPhotosModal/NoPhotosModal.vue'
import { CUSTOMER_CARE_MODALS_NAMES } from '~/scopes/customer-care/common/modals/names'
import InputFileUpload from '~/scopes/customer-care/resolution/components/common/InputFileUpload/InputFileUpload.vue'

import translations from './PicturesScreen.translations'
import type {
  ContinueCta,
  InputFileRef,
  PicturesScreenProps,
} from './PicturesScreen.types'

const props = defineProps<PicturesScreenProps>()

const pictures = ref(props.pictures ?? [])
const inputFile = ref<InputFileRef>()

const { ZENDESK_PRODUCT_RETURN_FAQ_ARTICLE } = useRuntimeConfig().public
const i18n = useI18n()
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const examplesFaqArticleLink = toNuxtLink({
  type: 'external',
  href: `${zendeskFaqBaseUrl}/articles/${isConfigDefined(ZENDESK_PRODUCT_RETURN_FAQ_ARTICLE, 'ZENDESK_PRODUCT_RETURN_FAQ_ARTICLE')}`,
})

const continueCta = computed<ContinueCta>(() => {
  if (isEmpty(pictures.value)) {
    return {
      variant: ButtonVariants.Secondary,
      label: i18n(translations.continueWithoutPhotos),
    }
  }

  return {
    variant: ButtonVariants.Primary,
    label: i18n(translations.continue),
  }
})

const onConfirmSubmit = () => {
  props.machineSend({
    type: 'CONTINUE',
    payload: {
      pictures: pictures.value ?? [],
    },
  })
}

const onTriggerInputFile = () => {
  const input = inputFile.value?.RevInputFileUploadRef.$refs
    .input as HTMLElement
  input?.click()
}

const onSubmit = () => {
  if (isEmpty(pictures.value)) {
    return openModal(CUSTOMER_CARE_MODALS_NAMES.noPhotosModal)
  }

  return onConfirmSubmit()
}

onMounted(() => window.scrollTo(0, 0))
</script>
