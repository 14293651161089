export default {
  breadCrumbTitle: {
    id: 'express_replacement_breadcrumb_title',
    defaultMessage: 'Express Replacement',
  },
  stepperAltTextBack: {
    id: 'express_replacement_stepper_alt_text_back',
    defaultMessage: 'Back',
  },
  stepperAltTextClose: {
    id: 'express_replacement_stepper_alt_text_close',
    defaultMessage: 'Close',
  },
  stepperAltTextCompleted: {
    id: 'express_replacement_stepper_alt_text_completed',
    defaultMessage: 'Completed',
  },
  stepperAltTextCurrent: {
    id: 'express_replacement_stepper_alt_text_current',
    defaultMessage: 'Current',
  },
  stepperShipping: {
    id: 'express_replacement_stepper_shipping',
    defaultMessage: 'Shipping',
  },
  stepperDataRemoval: {
    id: 'express_replacement_stepper_data_removal',
    defaultMessage: 'Erase your data',
  },
  stepperPictures: {
    id: 'express_replacement_stepper_pictures',
    defaultMessage: 'Add photos',
  },
  stepperContact: {
    id: 'express_replacement_stepper_contact',
    defaultMessage: 'Describe your issue',
  },
  stepperPayment: {
    id: 'express_replacement_stepper_payment',
    defaultMessage: 'Credit card',
  },
}
