export default {
  errorToastTitle: {
    id: 'dashboard_diagnosis_contact_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
  inputFileUploadEmptyLabel: {
    id: 'dashboard_diagnosis_contact_form_pictures_empty_placeholder',
    defaultMessage: 'Attach photos',
  },
  inputFileUploadNoEmptyLabel: {
    id: 'dashboard_diagnosis_contact_form_pictures_not_empty_placeholder',
    defaultMessage: 'Select more photos',
  },
  inputFileDescription: {
    id: 'dashboard_diagnosis_photo_step_file_format_hint',
    defaultMessage: 'jpeg, png, pdf or heif files of less than {size} each',
  },
  inputFileErrorMaxSize: {
    id: 'dashboard_diagnosis_contact_form_too_large',
    defaultMessage: 'Those photos are too large to upload. Give it another go.',
  },
  inputFileErrorWrongFileTypeAndTooLarge: {
    id: 'dashboard_diagnosis_contact_form_wrong_type_too_large',
    defaultMessage:
      'Those photos have the wrong file type and too large to upload. Give it another go.',
  },
  inputFileErrorWrongFileType: {
    id: 'dashboard_diagnosis_contact_form_wrong_type',
    defaultMessage:
      'Those photos have the wrong file type. Give it another go.',
  },
}
