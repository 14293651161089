export default {
  backToOrdersCta: {
    id: 'dashboard_diagnosis_fail_loading_my_orders',
    defaultMessage: 'Back to My Orders',
  },
  errorToastCloseAlternativeTest: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  errorToastDescription: {
    id: 'dashboard_diagnosis_contact_form_error_message',
    defaultMessage:
      'We couldn’t send your message. Check your network connection and try again.',
  },
  errorToastTitle: {
    id: 'dashboard_diagnosis_contact_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
  heading: {
    id: 'resolution_flow_orderline_lifecycle_contact_screen_heading',
    defaultMessage: 'OK, please drop us a line',
  },
  notValidatedInfoBlockContent: {
    id: 'resolution_flow_orderline_status_preparation_should_not_take',
    defaultMessage:
      'Normally, preparation shouldn’t take more than {date}. You can send us your questions or concerns below.',
  },
  notDeliveredInfoBlockContent: {
    id: 'resolution_flow_orderline_status_should_have_been_delivered',
    defaultMessage:
      'Your order should have been delivered by {date}. Send us your questions or concerns below.',
  },
  notShippedInfoBlockContent: {
    id: 'resolution_flow_orderline_status_should_have_shipped',
    defaultMessage:
      'Your order should have shipped by {date}. You can send us your questions or concerns below.',
  },
}
