<template>
  <MinimalWithSideBarLayout
    :disableCancelButton="displayReturnScreens || isExpressReplacement"
    :progress-bar-current-value="getProgressBarValue"
    :with-mobile-web-sidebar="!displayReturnScreens && !isExpressReplacement"
    @back="goBack"
    @cancel="emit('cancel')"
    @scroll-child-element="handleScrollInElement"
  >
    <template #sidebar>
      <div :class="withoutMwebOrderlineRecapBlock ? ' max-md:pb-16 ' : ''">
        <OrderlineRecapBlock
          :class="withoutMwebOrderlineRecapBlock ? 'max-md:hidden ' : ''"
          :order-id
          :product-image
          :product-title
        />
      </div>
    </template>

    <template #content>
      <OrderlineLifecycleScreen
        v-if="displayOrderlineLifecycleScreen"
        :machine-send
        :machine-state
      />

      <OrderlineLifecycleContactScreen
        v-if="displayOrderlineLifecycleContactScreen"
        data-qa="resolution-flow-orderline-lifecycle-contact-screen"
        data-test="resolution-flow-orderline-lifecycle-contact-screen"
        :machine-send
        :machine-state
      />

      <SelfTroubleshootingScreen
        v-if="displaySelfTroubleshootingScreen"
        :articles="machineState.context.selfTroubleshootingArticles"
        data-qa="self-troubleshooting-screen"
        data-test="self-troubleshooting-screen"
        :machine-send
      />

      <RemoteAssistanceContactScreen
        v-if="displayRemoteAssistanceContactScreen"
        data-qa="resolution-flow-remote-assistance-contact-screen"
        data-test="resolution-flow-remote-assistance-contact-screen"
        :machine-send
        :machine-state
      />

      <ResolutionOptionScreen
        v-if="
          displayResolutionOptionsScreen &&
          machineState.context.orderline &&
          machineState.context.resolutionOptions
        "
        :bundled-mobile-plan="machineState.context.orderline.bundledMobilePlan"
        data-qa="resolution-flow-resolution-options-screen"
        data-test="resolution-flow-resolution-options-screen"
        :machine-send
        :machine-state
        :repair-options-unavailable="
          machineState.context.repairOptionsUnavailable
        "
        :resolution-options="machineState.context.resolutionOptions"
      />

      <WarrantyExclusionContactScreen
        v-if="displayWarrantyExclusionContactScreen"
        data-qa="resolution-flow-warranty-exclusion-contact-screen"
        data-test="resolution-flow-warranty-exclusion-contact-screen"
        :machine-send
        :machine-state
      />
      <WarrantyExclusionScreen
        v-if="displayWarrantyExclusionScreen"
        data-qa="resolution-flow-warranty-exclusion-screen"
        data-test="resolution-flow-warranty-exclusion-screen"
        :machine-send
      />
      <ReturnScreen
        v-if="displayReturnScreens"
        :machine-send
        :machine-state
        @close="emit('cancel')"
      />
      <ExpressReplacement v-if="isExpressReplacement" />
    </template>
  </MinimalWithSideBarLayout>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { computed } from 'vue'

import OrderlineRecapBlock from '@/scopes/customer-care/common/components/OrderlineRecapBlock/OrderlineRecapBlock.vue'
import { useScrollState } from '@/scopes/customer-care/common/composables/useScrollState'
import MinimalWithSideBarLayout from '@/scopes/customer-care/common/layout/MinimalWithSideBarLayout.vue'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import {
  progressBarMatchesStates,
  progressBarValue,
  withoutMwebOrderlineRecapBlockMatchesStates,
} from './ResolutionFlowScreens.constants'
import type { ResolutionFlowScreensProps } from './ResolutionFlowScreens.types'
import ResolutionOptionScreen from './screens/DiagnosticToResolutions/ResolutionOptionScreen/ResolutionOptionScreen.vue'
import SelfTroubleshootingScreen from './screens/DiagnosticToResolutions/SelfTroubleshootingScreen/SelfTroubleshootingScreen.vue'
import WarrantyExclusionContactScreen from './screens/DiagnosticToResolutions/WarrantyExclusionContactScreen/WarrantyExclusionContactScreen.vue'
import WarrantyExclusionScreen from './screens/DiagnosticToResolutions/WarrantyExclusionScreen/WarrantyExclusionScreen.vue'
import ExpressReplacement from './screens/ExpressReplacement/ExpressReplacement.vue'
import OrderlineLifecycleContactScreen from './screens/OrderlineLifecycle/OrderlineLifecycleContactScreen/OrderlineLifecycleContactScreen.vue'
import OrderlineLifecycleScreen from './screens/OrderlineLifecycle/OrderlineLifecycleScreen/OrderlineLifeCycleScreen.vue'
import RemoteAssistanceContactScreen from './screens/RemoteAssistanceContactScreen/RemoteAssistanceContactScreen.vue'
import ReturnScreen from './screens/Return/ReturnScreen.vue'

const props = defineProps<ResolutionFlowScreensProps>()
const emit = defineEmits(['cancel'])

const route = useRoute()
const { back } = useRouter()

const { handleScrollInElement } = useScrollState()

const displayOrderlineLifecycleScreen = computed(() =>
  props.machineState.matches('ORDERLINE_LIFECYCLE'),
)

const displayOrderlineLifecycleContactScreen = computed(
  () =>
    props.machineState.matches('CONTACT') &&
    props.machineState.context.orderlineLifecycleContactForm,
)

const displaySelfTroubleshootingScreen = computed(() =>
  props.machineState.matches('SELF_TROUBLESHOOTING'),
)

const displayRemoteAssistanceContactScreen = computed(
  () =>
    props.machineState.matches('CONTACT') &&
    props.machineState.context.remoteAssistanceContactForm,
)

const displayWarrantyExclusionContactScreen = computed(
  () =>
    props.machineState.matches('CONTACT') &&
    props.machineState.context.warrantyExclusionContactForm,
)

const displayWarrantyExclusionScreen = computed(() =>
  props.machineState.matches('OUT_OF_WARRANTY'),
)

const displayResolutionOptionsScreen = computed(() =>
  props.machineState.matches('RESOLUTION_OPTION'),
)

const displayReturnScreens = computed(() =>
  props.machineState.matches('RETURN_FUNNEL'),
)

const isExpressReplacement = computed(() =>
  route.matched.some(
    ({ name }) => name === CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.SELF,
  ),
)

const getProgressBarValue = computed(() => {
  if (isExpressReplacement.value) {
    return undefined
  }
  const value = progressBarMatchesStates.filter(props.machineState.matches)[0]

  if (progressBarValue[value]) return progressBarValue[value]

  return undefined
})

const withoutMwebOrderlineRecapBlock = computed(() =>
  withoutMwebOrderlineRecapBlockMatchesStates.some(props.machineState.matches),
)

function goBack() {
  if (isExpressReplacement.value) {
    back()

    return
  }
  props.machineSend('BACK')
}
</script>
