export default {
  addPhotosNow: {
    id: 'dashboard_diagnosis_photo_step_modal_cta',
    defaultMessage: 'Add photos now',
  },
  continue: {
    id: 'dashboard_diagnosis_continue',
    defaultMessage: 'Continue',
  },
  continueWithoutPhotos: {
    id: 'dashboard_diagnosis_photo_step_continue_without_photos',
    defaultMessage: 'Continue without photos',
  },
  errorToastTitle: {
    id: 'dashboard_diagnosis_contact_form_error_toast_title',
    defaultMessage: 'Hold up!',
  },
  fileFormatHint: {
    id: 'dashboard_diagnosis_photo_step_file_format_hint',
    defaultMessage: 'jpeg, png, pdf or heif files of less than {size} each',
  },
  followTheseSteps: {
    id: 'dashboard_diagnosis_prepare_step_prepare_return_sub_heading',
    defaultMessage:
      'Follow these steps to get your item ready and submit a return request.',
  },
  heading: {
    id: 'dashboard_diagnosis_photo_step_take_photos',
    defaultMessage: 'Take photos',
  },
  noPhotosHeading: {
    id: 'dashboard_diagnosis_photo_step_modal_title',
    defaultMessage: 'No photos?',
  },
  noPhotosSuggestion: {
    id: 'dashboard_diagnosis_photo_step_modal_text',
    defaultMessage:
      'We suggest you attach at least 4 photos of your item in case something happens to it in transit.',
  },
  noThanks: {
    id: 'dashboard_diagnosis_photo_step_modal_cancel',
    defaultMessage: 'No thanks',
  },
  oneBackPhoto: {
    id: 'dashboard_diagnosis_photo_step_need_photo_each_side',
    defaultMessage: 'One photo of each side (4 photos)',
  },
  onePhotoPerSide: {
    id: 'dashboard_diagnosis_photo_step_need_photo_back_surface',
    defaultMessage: 'One photo of the back surface',
  },
  photoSuggestion: {
    id: 'dashboard_diagnosis_photo_step_take_photos_sub_heading',
    defaultMessage:
      'We suggest you take a few photos of your item from all angles before sending it. This will protect you in case it’s damaged while in transit.',
  },
  picturesEmptyPlaceholder: {
    id: 'dashboard_diagnosis_contact_form_pictures_empty_placeholder',
    defaultMessage: 'Attach photos',
  },
  picturesNotEmptyPlaceholder: {
    id: 'dashboard_diagnosis_contact_form_pictures_not_empty_placeholder',
    defaultMessage: 'Select more photos',
  },
  prepareYourReturn: {
    id: 'dashboard_diagnosis_prepare_step_prepare_return',
    defaultMessage: 'Prepare your return',
  },
  seeExamples: {
    id: 'dashboard_diagnosis_photo_step_see_examples',
    defaultMessage: 'See examples',
  },
  toastClose: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  twoScreenPhotos: {
    id: 'dashboard_diagnosis_photo_step_need_photos_screen',
    defaultMessage:
      'Two photos of the screen (switched on and off, if possible)',
  },
  whatWeNeed: {
    id: 'dashboard_diagnosis_photo_step_need_photos_title',
    defaultMessage: 'What do we need?',
  },
}
