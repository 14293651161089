import type { StateSchema, StatesConfig } from 'xstate'

import type { MachineContext } from '~/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'

import type { ReturnFunnelMachineEvents } from './ReturnFunnel.types'

export const ReturnFunnelState: StatesConfig<
  MachineContext,
  StateSchema<MachineContext>,
  ReturnFunnelMachineEvents
> = {
  START: {
    entry: 'goToNextPage',
  },
  CREATING_SHIPMENT: {
    initial: 'LOADING',
    states: {
      LOADING: {
        tags: ['loading'],
        invoke: {
          id: 'createShipment',
          src: 'createShipment',
          onDone: {
            actions: ['storeShipment', 'goToNextPage'],
          },
          onError: [
            {
              cond: 'isInvalidProblemErrorType',
              target: 'LOADING_PROBLEM_CONTEXT_FAILED',
            },
            // proceed without shipping, agent will have to create it manually
            {
              actions: ['logErrorFromCreateShipmentService', 'goToNextPage'],
            },
          ],
        },
      },
      LOADING_PROBLEM_CONTEXT_FAILED: {
        tags: ['loading_problem_context_failed'],
        type: 'final',
      },
    },
  },
  DATA_REMOVAL_INTRO: {
    on: {
      CONTINUE: {
        actions: [
          'storeDataRemovedPayload',
          'storeHistoryDataRemoval',
          'goToNextPage',
        ],
      },
    },
  },
  PICTURES_INTRO: {
    on: {
      CONTINUE: {
        actions: [
          'storeReturnFunnelPicturesPayload',
          'storeHistoryPicturesIntro',
          'goToNextPage',
        ],
      },
    },
  },
  PICTURES: {
    on: {
      CONTINUE: {
        actions: [
          'storeReturnFunnelPicturesPayload',
          'storeHistoryPictures',
          'goToNextPage',
        ],
      },
    },
  },
  DESCRIPTION: {
    on: {
      CONTINUE: [
        {
          actions: [
            'storeReturnFunnelDescriptionPayload',
            'storeHistoryDescription',
            'goToNextPage',
          ],
        },
      ],
    },
  },
  INITIATING_RESOLUTION: {
    initial: 'LOADING',
    states: {
      LOADING: {
        tags: ['loading'],
        invoke: {
          id: 'initiateResolution',
          src: 'initiateResolution',
          onDone: {
            actions: [
              'storeInitiatedResolutionPayload',
              'trackHelpRequestCreated',
            ],
            target: '#RESOLUTION_INITIATED',
          },

          onError: [
            {
              cond: 'isInvalidProblemErrorType',
              target: 'LOADING_PROBLEM_CONTEXT_FAILED',
            },
            {
              actions: 'logErrorFromInitiateResolutionService',
              target: '#RETURN_FUNNEL.SUBMISSION_ERROR',
            },
          ],
        },
      },
      LOADING_PROBLEM_CONTEXT_FAILED: {
        tags: ['loading_problem_context_failed'],
        type: 'final',
      },
    },
  },
  SUBMISSION_ERROR: {
    on: {
      SEND_REQUEST: 'INITIATING_RESOLUTION',
    },
  },
}
