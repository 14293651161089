import { type ServiceMap, type Typestate, createMachine, raise } from 'xstate'

import { ReturnFunnelActions } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.actions'
import { ReturnFunnelSchema } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.schema'

import { every, some } from '../../common/machine/guards/guards'

import { ResolutionFlowMachineActions } from './ResolutionFlow.machine.actions'
import { ResolutionFlowMachineGuards } from './ResolutionFlow.machine.guards'
import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
} from './ResolutionFlow.machine.types'
import { createShipment } from './services/createShipment'
import { getIssueData } from './services/getIssueData'
import { getOrderlineTimeline } from './services/getOrderlineTimeline'
import { getResolutionOptions } from './services/getResolutionOptions'
import { getSelfTroubleshootingArticles } from './services/getSelfTroubleshootingArticles'
import { initiateResolution } from './services/initiateResolution'

const onContactRefurbisher = {
  CONTACT_REFURBISHER: {
    actions: 'storeContactFormPayload',
    cond: 'validateContactFormPayload',
    target: 'INITIATING_RESOLUTION',
  },
}

export function createResolutionFlowMachine(
  initialState: string | null = null,
) {
  return createMachine<
    MachineContext,
    MachineEvents,
    Typestate<MachineContext>,
    ServiceMap,
    MachineStateSchema
  >(
    {
      id: 'resolution',
      initial: initialState ?? 'GET_DIAGNOSIS_ACTION',
      preserveActionOrder: true,
      predictableActionArguments: true,
      schema: {
        context: {} as MachineContext,
        events: {} as MachineEvents,
      },
      context: {
        activeAction: null,
        createdClaim: {
          customerRequestId: null,
          redirectUrl: null,
        },
        customerInput: {
          areDataRemoved: null,
          declaredWarrantyState: null,
          description: null,
          needMoreHelp: false,
          pictures: [],
          resolutionOptions: [],
        },
        diagnosisV2Action: null,
        diagnosisV2CustomerIssues: [],
        diagnosisV2CustomerIssuesTags: [],
        firstStateAfterLoading: null,
        FF_ENABLE_SELF_TROUBLESHOOTING: false,
        initiatedResolution: null,
        isEligibleToProductReturn: false,
        mitigation: null,
        needDataRemoval: false,
        orderline: null,
        orderlineId: null,
        orderlineLifecycleContactForm: false,
        orderlineTimeline: null,
        pageHistory: [],
        pointOfContact: null,
        publicId: null,
        resolutionOptions: [],
        selfTroubleshootingArticles: [],
        shipment: null,
        shipmentId: null,
        remoteAssistanceContactForm: false,
        repairOptionsUnavailable: false,
        warrantyExclusionContactForm: false,
        xrData: null,
      },
      states: {
        GET_DIAGNOSIS_ACTION: {
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: ['loading'],
              invoke: {
                id: 'getIssueData',
                src: 'getIssueData',
                onDone: {
                  actions: 'storeIssueData',
                  target: 'LOADED',
                },
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromGetIssueDataService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADED: {
              always: [
                {
                  cond: some(
                    'isCustomerIssueOrderlineLateDelivery',
                    'isCustomerIssueOrderlineNotShipped',
                    'isCustomerIssueOrderlineNotValidated',
                  ),
                  target: '#ORDERLINE_LIFECYCLE',
                },
                {
                  actions: [
                    'storePointOfContactBmWarrantyClaim',
                    'storeContactAsFirstState',
                  ],
                  cond: 'isBmWarrantyClaimTargetFromDiagnosisV2',
                  target: '#CONTACT',
                },
                {
                  actions: [
                    'storePointOfContactMerchantClaim',
                    'storeContactAsFirstState',
                  ],
                  cond: 'isRemoteAssistanceTargetFromDiagnosisV2',
                  target: '#CONTACT',
                },
                {
                  actions: [
                    'storePointOfContactMerchantClaim',
                    'storeSelfTroubleshootingAsFirstState',
                  ],
                  cond: some(
                    'isGetResolutionOptionsTargetFromDiagnosisV2',
                    'isGetResolutionOptionsWithoutWarrantyExclusionTarget',
                  ),
                  target: '#SELF_TROUBLESHOOTING',
                },
              ],
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'LOADING',
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: ['loading_problem_context_failed'],
              type: 'final',
            },
          },
        },
        ORDERLINE_LIFECYCLE: {
          id: 'ORDERLINE_LIFECYCLE',
          initial: 'INIT',
          on: {
            CONTACT_SELLER: '#CONTACT',
            NAVIGATE_TO_RESOLUTION_EXPERIENCE: {
              actions: 'storePointOfContactMerchantClaim',
              target: '#SELF_TROUBLESHOOTING',
            },
            BACK: {
              actions: 'navigateToDiagnosisFlow',
              type: 'final',
            },
          },
          states: {
            INIT: {
              always: [
                {
                  actions: 'storePointOfContactMerchantClaim',
                  cond: 'isGetResolutionOptionsTargetFromDiagnosisV2',
                  target: '#OUT_OF_WARRANTY',
                },
                {
                  target: 'LOADING',
                },
              ],
            },
            LOADING: {
              tags: ['loading'],
              entry: 'storePointOfContactMerchantClaim',
              exit: 'storeOrderlineTimelinePayload',
              invoke: {
                id: 'getOrderlineTimeline',
                src: 'getOrderlineTimeline',
                onDone: [
                  {
                    actions: 'storeLateDeliveryFormAsFirstStateAfterLoading',
                    cond: every(
                      'isCustomerIssueOrderlineLateDelivery',
                      'isRemoteAssistanceTargetFromDiagnosisV2',
                    ),
                    target: '#CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM',
                  },
                  {
                    actions: 'storeNotShippedFormAsFirstStateAfterLoading',
                    cond: every(
                      'isCustomerIssueOrderlineNotShipped',
                      'isRemoteAssistanceTargetFromDiagnosisV2',
                    ),
                    target: '#CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM',
                  },
                  {
                    actions: 'storeNotValidatedFormAsFirstStateAfterLoading',
                    cond: every(
                      'isCustomerIssueOrderlineNotValidated',
                      'isRemoteAssistanceTargetFromDiagnosisV2',
                    ),
                    target: '#CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM',
                  },
                  {
                    cond: 'isCustomerIssueOrderlineLateDelivery',
                    target: 'ORDERLINE_LATE_DELIVERY',
                  },
                  {
                    cond: 'isCustomerIssueOrderlineNotShipped',
                    target: 'ORDERLINE_NOT_SHIPPED',
                  },
                  {
                    cond: 'isCustomerIssueOrderlineNotValidated',
                    target: 'ORDERLINE_NOT_VALIDATED',
                  },
                ],
                onError: {
                  actions: 'logErrorFromOrderlineTimelineService',
                  target: 'LOADING_FAILED',
                },
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: {
                  target: 'LOADING',
                },
              },
            },
            ORDERLINE_LATE_DELIVERY: {
              entry: 'storeOrderlineLifecycleLateDeliveryAsFirstState',
            },
            ORDERLINE_NOT_SHIPPED: {
              entry: 'storeOrderlineLifecycleNotShippedAsFirstState',
            },
            ORDERLINE_NOT_VALIDATED: {
              entry: 'storeOrderlineLifecycleNotValidatedAsFirstState',
            },
          },
        },
        SELF_TROUBLESHOOTING: {
          id: 'SELF_TROUBLESHOOTING',
          initial: 'LOADING',
          on: {
            NAVIGATE_TO_NEXT_SCREEN: [
              {
                actions: 'storeOutOfWarrantyPayload',
                cond: 'isGetResolutionOptionsWithoutWarrantyExclusionTarget',
                target: '#RESOLUTION_OPTIONS_LOADING',
              },
              {
                target: '#OUT_OF_WARRANTY',
              },
            ],
          },
          states: {
            LOADING: {
              tags: ['loading'],
              invoke: {
                id: 'getSelfTroubleshootingArticles',
                src: 'getSelfTroubleshootingArticles',
                onDone: [
                  {
                    actions: 'storeSelfTroubleshootingArticles',
                    cond: 'hasSelfTroubleshootingArticles',
                    target: 'LOADED',
                  },
                  {
                    actions: raise('NAVIGATE_TO_NEXT_SCREEN'),
                  },
                ],
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions:
                      'logErrorFromGetSelftroubleshootingArticlesService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADED: {
              entry: 'trackScreenViewSelfTroubleshooting',
              on: {
                BACK: {
                  actions: 'navigateToDiagnosisFlow',
                  type: 'final',
                },
                CONTINUE: {
                  actions: [
                    'trackClickSelfTroubleshootingContinue',
                    raise('NAVIGATE_TO_NEXT_SCREEN'),
                  ],
                },

                CLICK_ARTICLE: {
                  actions: 'trackClickSelfTroubleshootingArticle',
                },
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'LOADING',
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: ['loading_problem_context_failed'],
              type: 'final',
            },
          },
        },
        OUT_OF_WARRANTY: {
          id: 'OUT_OF_WARRANTY',
          entry: 'clearCustomerInputResolutionOptions',
          on: {
            BACK: [
              // since we've already been to the troubleshooting state this means that there are articles to go back to
              {
                cond: 'isSelfTroubleshootingPreviousState',
                target: 'SELF_TROUBLESHOOTING.LOADED',
              },
              {
                actions: 'navigateToDiagnosisFlow',
                type: 'final',
              },
            ],
            SUBMIT: [
              {
                actions: 'storeOutOfWarrantyPayload',
                cond: 'isOutOfWarrantyPayload',
                target: '#CONTACT.WARRANTY_EXCLUSION_FORM',
              },
              {
                actions: 'storeOutOfWarrantyPayload',
                cond: 'isMerchantClaim',
                target: 'RESOLUTION_OPTIONS_LOADING',
              },
            ],
          },
        },
        RESOLUTION_OPTIONS_LOADING: {
          id: 'RESOLUTION_OPTIONS_LOADING',
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: ['loading'],
              invoke: {
                id: 'getResolutionOptions',
                src: 'getResolutionOptions',
                onDone: [
                  {
                    actions: 'storeResolutionOptionsData',
                    cond: 'isMerchantClaimWithOnlyAssistance',
                    target: '#CONTACT',
                  },
                  {
                    actions: 'storeResolutionOptionsData',
                    target: '#RESOLUTION_OPTION',
                  },
                ],
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromGetResolutionOptionsService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'LOADING',
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: ['loading_problem_context_failed'],
              type: 'final',
            },
          },
        },
        RESOLUTION_OPTION: {
          id: 'RESOLUTION_OPTION',
          entry: 'trackScreenViewResolutionOption',
          exit: ['trackClickResolutionOptionCard', 'hideIntercomMessanger'],
          on: {
            BACK: [
              {
                actions: 'navigateToDiagnosisFlow',
                cond: every(
                  'isGetResolutionOptionsWithoutWarrantyExclusionTarget',
                  'hasEmptySelfTroubleshootingArticles',
                ),
                type: 'final',
              },
              {
                actions: 'clearShipment',
                cond: 'isGetResolutionOptionsWithoutWarrantyExclusionTarget',
                target: 'SELF_TROUBLESHOOTING.LOADED',
              },
              {
                actions: 'clearShipment',
                target: 'OUT_OF_WARRANTY',
              },
            ],
            CONTINUE: [
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                ],
                cond: 'isRemoteAssistancePayload',
                target: 'CONTACT',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                  'navigateToExpressReplacement',
                ],
                cond: 'isExpressReplacementPayload',
                target: 'EXPRESS_REPLACEMENT',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                  'storeHistoryResolutionOption',
                ],
                cond: every(
                  'isValidSelectedResolutionOption',
                  'shouldGoToReturnFunnel',
                ),
                target: 'RETURN_FUNNEL',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                ],
                cond: 'isLiveChatPayload',
                target: 'LIVE_CHAT_RESOLUTION',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                ],
                cond: 'isChatPayload',
                target: '.INTERCOM',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                ],
                cond: 'isStraightRefundPayload',
                target: 'STRAIGHT_REFUND_RESOLUTION',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                ],
                cond: every(
                  'isValidSelectedResolutionOption',
                  'shouldGoToContactForm',
                ),
                target: 'CONTACT',
              },
              {
                actions: [
                  'clearShipmentIfNewResolutionOption',
                  'storeResolutionOptionPayload',
                  'logErrorUnhandledResolutionSelection',
                ],
                target: 'CONTACT',
              },
            ],
          },
          initial: 'IDLE',
          states: {
            IDLE: {},
            INTERCOM: {
              id: 'INTERCOM',
              initial: 'LOADING',
              states: {
                LOADING: {
                  tags: ['loading_button'],
                  invoke: {
                    id: 'initiateResolution',
                    src: 'initiateResolution',
                    onDone: {
                      actions: 'showIntercomMessanger',
                      target: '#RESOLUTION_OPTION.IDLE',
                    },
                    onError: {
                      actions: 'logErrorFromInitiateResolutionService',
                      target: '#RESOLUTION_OPTION.INTERCOM.LOADING_FAILED',
                    },
                  },
                },
                LOADING_FAILED: {
                  id: 'LOADING_FAILED',
                  on: {
                    CLOSE: {
                      target: '#RESOLUTION_OPTION.IDLE',
                    },
                  },
                },
              },
            },
          },
        },
        LIVE_CHAT_RESOLUTION: {
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: ['loading'],
              invoke: {
                id: 'initiateResolution',
                src: 'initiateResolution',
                onDone: {
                  actions: [
                    'storeInitiatedResolutionPayload',
                    'trackHelpRequestCreated',
                  ],
                  target: '#RESOLUTION_INITIATED',
                },
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromInitiateResolutionService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'LOADING',
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: ['loading_problem_context_failed'],
              type: 'final',
            },
          },
        },
        RETURN_FUNNEL: {
          id: 'RETURN_FUNNEL',
          initial: 'START',
          ...ReturnFunnelSchema,
        },
        STRAIGHT_REFUND_RESOLUTION: {
          initial: 'LOADING',
          states: {
            LOADING: {
              tags: ['loading'],
              invoke: {
                id: 'initiateResolution',
                src: 'initiateResolution',
                onDone: {
                  actions: [
                    'storeInitiatedResolutionPayload',
                    'trackHelpRequestCreated',
                  ],
                  target: '#RESOLUTION_INITIATED',
                },
                onError: [
                  {
                    cond: 'isInvalidProblemErrorType',
                    target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                  },
                  {
                    actions: 'logErrorFromInitiateResolutionService',
                    target: 'LOADING_FAILED',
                  },
                ],
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'LOADING',
              },
            },
            LOADING_PROBLEM_CONTEXT_FAILED: {
              tags: ['loading_problem_context_failed'],
              type: 'final',
            },
          },
        },
        EXPRESS_REPLACEMENT: {
          id: 'EXPRESS_REPLACEMENT',
          initial: 'IDLE',
          on: {
            BACK: {
              target: '#RESOLUTION_OPTION',
            },
            SET_XR_DATA: {
              actions: 'storeXrData',
            },
          },
          states: {
            IDLE: {
              on: {
                ERROR: {
                  target: 'LOADING_FAILED',
                },
              },
            },
            LOADING_FAILED: {
              tags: ['loading_failed'],
              on: {
                RETRY: 'IDLE',
              },
            },
          },
        },
        CONTACT: {
          id: 'CONTACT',
          initial: 'FORM',
          exit: 'clearActiveContactForm',
          on: {
            BACK: [
              {
                cond: 'isContactOrderlineLifecycleAsFirststate',
                actions: 'navigateToDiagnosisFlow',
                type: 'final',
              },
              {
                cond: 'isCustomerIssueOrderlineLateDelivery',
                target: 'ORDERLINE_LIFECYCLE.ORDERLINE_LATE_DELIVERY',
              },
              {
                cond: 'isCustomerIssueOrderlineNotShipped',
                target: 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_SHIPPED',
              },
              {
                cond: 'isCustomerIssueOrderlineNotValidated',
                target: 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_VALIDATED',
              },
              {
                cond: 'isMerchantClaimWithOnlyAssistance',
                target: 'OUT_OF_WARRANTY',
              },
              {
                cond: 'isOutOfWarranty',
                target: 'OUT_OF_WARRANTY',
              },
              {
                cond: 'isMerchantClaimWithOptions',
                target: 'RESOLUTION_OPTION',
              },
              {
                actions: 'navigateToDiagnosisFlow',
                type: 'final',
              },
            ],
          },
          states: {
            FORM: {
              entry: 'storeRemoteAssistanceContactFormAsActive',
              on: {
                ...onContactRefurbisher,
              },
            },
            WARRANTY_EXCLUSION_FORM: {
              entry: 'storeWarrantyExclusionContactFormAsActive',
              on: {
                ...onContactRefurbisher,
              },
            },
            ORDERLINE_TIMELINE_LATE_DELIVERY_FORM: {
              entry: 'storeOrderlineLifecycleContactFormAsActive',
              on: {
                ...onContactRefurbisher,
              },
            },
            ORDERLINE_TIMELINE_NOT_SHIPPED_FORM: {
              entry: 'storeOrderlineLifecycleContactFormAsActive',
              on: {
                ...onContactRefurbisher,
              },
            },
            ORDERLINE_TIMELINE_NOT_VALIDATED_FORM: {
              entry: 'storeOrderlineLifecycleContactFormAsActive',
              on: {
                ...onContactRefurbisher,
              },
            },
            SUBMISSION_ERROR: {
              id: 'SUBMISSION_ERROR',
              on: {
                ...onContactRefurbisher,

                GO_TO_FORM: [
                  {
                    cond: 'isCustomerIssueOrderlineLateDelivery',
                    target: 'ORDERLINE_TIMELINE_LATE_DELIVERY_FORM',
                  },
                  {
                    cond: 'isCustomerIssueOrderlineNotShipped',
                    target: 'ORDERLINE_TIMELINE_NOT_SHIPPED_FORM',
                  },
                  {
                    cond: 'isCustomerIssueOrderlineNotValidated',
                    target: 'ORDERLINE_TIMELINE_NOT_VALIDATED_FORM',
                  },
                  {
                    cond: 'isOutOfWarranty',
                    target: 'WARRANTY_EXCLUSION_FORM',
                  },
                  {
                    target: 'FORM',
                  },
                ],
              },
            },
            INITIATING_RESOLUTION: {
              initial: 'LOADING',
              states: {
                LOADING: {
                  tags: ['loading'],
                  invoke: {
                    id: 'initiateResolution',
                    src: 'initiateResolution',
                    onDone: {
                      actions: [
                        'storeInitiatedResolutionPayload',
                        'trackHelpRequestCreated',
                      ],
                      target: '#RESOLUTION_INITIATED',
                    },
                    onError: [
                      {
                        cond: 'isInvalidProblemErrorType',
                        target: 'LOADING_PROBLEM_CONTEXT_FAILED',
                      },
                      {
                        actions: 'logErrorFromInitiateResolutionService',
                        target: '#SUBMISSION_ERROR',
                      },
                    ],
                  },
                },
                LOADING_PROBLEM_CONTEXT_FAILED: {
                  tags: ['loading_problem_context_failed'],
                  type: 'final',
                },
              },
            },
          },
        },
        RESOLUTION_INITIATED: {
          id: 'RESOLUTION_INITIATED',
          always: [
            {
              cond: 'isLiveChatAsInitiatedResolution',
              target: 'CONVERSATION_PAGE',
            },
            {
              cond: 'isRemoteAssistanceAsInitiatedResolution',
              target: 'CONTACT_CONFIRMATION_PAGE',
            },
            {
              cond: 'isSellerTypeAsInitiatedResolution',
              target: 'RETURN_CONFIRMATION_PAGE',
            },
            {
              cond: 'isStraightRefundAsInitiatedResolution',
              target: 'STRAIGHT_REFUND_CONFIRMATION_PAGE',
            },
          ],
        },
        CONTACT_CONFIRMATION_PAGE: {
          id: 'CONTACT_CONFIRMATION_PAGE',
          entry: 'navigateToContactConfirmation',
          tags: ['loading'],
          type: 'final',
        },
        CONVERSATION_PAGE: {
          id: 'CONVERSATION_PAGE',
          entry: 'navigateToConversation',
          tags: ['loading'],
          type: 'final',
        },
        RETURN_CONFIRMATION_PAGE: {
          id: 'RETURN_CONFIRMATION_PAGE',
          entry: 'navigateToReturnConfirmation',
          tags: ['loading'],
          type: 'final',
        },
        STRAIGHT_REFUND_CONFIRMATION_PAGE: {
          id: 'STRAIGHT_REFUND_CONFIRMATION_PAGE',
          entry: 'navigateToStraightRefundConfirmation',
          tags: ['loading'],
          type: 'final',
        },
      },
    },
    {
      actions: { ...ResolutionFlowMachineActions, ...ReturnFunnelActions },
      guards: ResolutionFlowMachineGuards,
      services: {
        createShipment,
        getIssueData,
        getSelfTroubleshootingArticles,
        getOrderlineTimeline,
        getResolutionOptions,
        initiateResolution,
      },
    },
  )
}

export const resolutionFlowMachine = createResolutionFlowMachine()
