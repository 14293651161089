import type { MachineStateSchema } from '../../machine/ResolutionFlow.machine.types'

export const WARRANTY_FAQ_ARTICLE = {
  id: '360010237699',
}

type ProgressBarMatchesStates = Extract<
  MachineStateSchema['matchesStates'],
  | 'CONTACT'
  | 'OUT_OF_WARRANTY'
  | 'RESOLUTION_OPTION'
  | 'SELF_TROUBLESHOOTING'
  | 'ORDERLINE_LIFECYCLE'
>

export const progressBarMatchesStates: ProgressBarMatchesStates[] = [
  'SELF_TROUBLESHOOTING',
  'OUT_OF_WARRANTY',
  'RESOLUTION_OPTION',
  'ORDERLINE_LIFECYCLE',
  'CONTACT',
]

export const progressBarValue: Record<
  ProgressBarMatchesStates,
  number | undefined
> = {
  SELF_TROUBLESHOOTING: 80,
  OUT_OF_WARRANTY: 90,
  RESOLUTION_OPTION: 100,
  ORDERLINE_LIFECYCLE: 100,
  CONTACT: 100,
}

type WithoutMwebOrderlineRecapBlockMatchesStates = Extract<
  MachineStateSchema['matchesStates'],
  | 'OUT_OF_WARRANTY'
  | 'RESOLUTION_OPTION'
  | 'SELF_TROUBLESHOOTING'
  | 'RETURN_FUNNEL'
>

export const withoutMwebOrderlineRecapBlockMatchesStates: WithoutMwebOrderlineRecapBlockMatchesStates[] =
  [
    'SELF_TROUBLESHOOTING',
    'OUT_OF_WARRANTY',
    'RESOLUTION_OPTION',
    'RETURN_FUNNEL',
  ]
