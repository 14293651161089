import { ORDERLINE_TIMELINE_STEP_TYPE } from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'

import type { MachineStateSchema } from '../../../../../machine/ResolutionFlow.machine.types'

import translations from './OrderlineLifeCycleScreen.translations'
import type { OrderLineLifeCycleScreenContentProperties } from './OrderlineLifeCycleScreen.types'

type OrderlineLifecycleStates = Extract<
  MachineStateSchema['matchesStates'],
  | 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_VALIDATED'
  | 'ORDERLINE_LIFECYCLE.ORDERLINE_LATE_DELIVERY'
  | 'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_SHIPPED'
>

export const orderlineLifecycleMatchesStates: OrderlineLifecycleStates[] = [
  'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_VALIDATED',
  'ORDERLINE_LIFECYCLE.ORDERLINE_LATE_DELIVERY',
  'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_SHIPPED',
]

export const content: Record<
  OrderlineLifecycleStates,
  OrderLineLifeCycleScreenContentProperties
> = {
  'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_VALIDATED': {
    heading: translations.headingOrderNotValidated,
    statusStatsInfoBlock: translations.statusInfoBlockOrderNotValidated,
    informativeStatus: translations.statusOrderNotValidated,
    informativeStatusDuration: {
      step: ORDERLINE_TIMELINE_STEP_TYPE.validation,
      isDayFormat: false,
      translation: translations.orderlineStatusInHoursDuration,
    },
  },
  'ORDERLINE_LIFECYCLE.ORDERLINE_LATE_DELIVERY': {
    heading: translations.headingOrderNotDelivered,
    statusStatsInfoBlock: translations.statusShippementInfoBlock,
    informativeStatus: translations.statusOrderNotDelivered,
    informativeStatusDuration: {
      step: ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      isDayFormat: true,
      translation: translations.orderlineStatusInBusinessDayDuration,
    },
  },
  'ORDERLINE_LIFECYCLE.ORDERLINE_NOT_SHIPPED': {
    heading: translations.headingOrderNotDelivered,
    statusStatsInfoBlock: translations.statusShippementInfoBlock,
    informativeStatus: translations.statusOrderNotShipped,
    informativeStatusDuration: {
      step: ORDERLINE_TIMELINE_STEP_TYPE.shipment,
      isDayFormat: true,
      translation: translations.orderlineStatusInDayDuration,
    },
  },
}
