<template>
  <div class="contents">
    <BundledServicesActiveModal @confirm="onConfirmBundledRefund" />

    <h2 class="heading-1 mb-24" data-qa="title-option">
      {{ i18n(translations.heading) }}
    </h2>

    <BundleInfo
      v-if="displayBundleInfo"
      :cancellable-bundle="hasStraightRefundOptionAvailable"
      class="mb-24"
    />

    <ul v-if="formatedResolutionOptions" class="mb-24 list-none space-y-12">
      <li
        v-for="(resolutionOption, index) in formatedResolutionOptions"
        :key="index"
      >
        <component
          :is="getResolutionOptionCard(resolutionOption.type)"
          v-bind="
            resolutionOption.type === RESOLUTION_OPTION_TYPE_ENUM.chat
              ? { loading: displayLoadingButton }
              : {}
          "
          @select="onContinue"
        />
      </li>
    </ul>

    <div
      v-if="repairOptionsUnavailable"
      class="mb-24 flex w-full items-center gap-x-8"
    >
      <RevTooltip position="top">
        <template #trigger="{ show, hide }">
          <IconInfo
            @focusin="show"
            @focusout="hide"
            @mouseleave="hide"
            @mouseover="show"
          />
        </template>

        {{ i18n(translations.repairUnavailableTooltipContent) }}
      </RevTooltip>

      <p class="body-2">
        {{ i18n(translations.repairUnavailableInfoText) }}
      </p>
    </div>

    <div
      v-if="hasRemoteAssistanceOptionAvailable && !hasLiveChatOptionAvailable"
      class="mb-72"
    >
      <p class="body-2">
        {{ i18n(translations.getRemotAssistance) }}

        <RevLink
          data-qa="resolution-option-remote-assistance-link"
          @click="getHelpByEmail"
        >
          {{ i18n(translations.helpByEmail) }}
        </RevLink>
      </p>
    </div>
    <RevToast
      :close-alternative-text="
        i18n(translations.errorToastCloseAlternativeTest)
      "
      icon-src="/img/toast/storm.svg"
      name="resolution-flow-intercom-error-toast"
      :opened="hasSubmitError"
      :title="i18n(translations.errorToastTitle)"
      to="#toast-wrapper"
      variant="error"
      @close="handleCloseErrorToast"
    >
      {{ i18n(translations.errorToastDescription) }}
    </RevToast>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import {
  type GetResolutionOptionsTypeEnum,
  RESOLUTION_OPTION_TYPE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import type { ResolutionOption } from '@backmarket/http-api/src/api-specs-resolution-engine/types/getResolutionOptions'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevToast } from '@ds/components/Toast'
import { RevTooltip } from '@ds/components/Tooltip'
import { IconInfo } from '@ds/icons/IconInfo'

import type {
  IntercomEvents,
  MachineSend,
  ResolutionOptionsEvents,
} from '@/scopes/customer-care/resolution/machine/ResolutionFlow.machine.types'
import BundledServicesActiveModal from '@/scopes/customer-care/resolution/modals/BundledServicesActiveModal/BundledServicesActiveModal.vue'
import { RESOLUTION_FLOW_MODALS_NAMES } from '@/scopes/customer-care/resolution/modals/names'

import { resolutionOptionsConfig } from './ResolutionOptionScreen.config'
import translations from './ResolutionOptionScreen.translations'
import type { ResolutionOptionScreenProps } from './ResolutionOptionScreen.types'
import BundleInfo from './components/BundleInfo/BundleInfo.vue'
import { isResolutionOptionAvailable } from './utils/resolutionOptionAvailable'

const props = defineProps<ResolutionOptionScreenProps>()

const i18n = useI18n()

const send = props.machineSend as MachineSend<
  ResolutionOptionsEvents | IntercomEvents
>

const hasBundlePlan = computed(() => !isEmpty(props.bundledMobilePlan))

const hasLiveChatOptionAvailable = computed(() =>
  isResolutionOptionAvailable(
    props.resolutionOptions,
    RESOLUTION_OPTION_TYPE_ENUM.liveChat,
  ),
)

const hasRefundOptionAvailable = computed(() =>
  isResolutionOptionAvailable(
    props.resolutionOptions,
    RESOLUTION_OPTION_TYPE_ENUM.refund,
  ),
)

const hasRemoteAssistanceOptionAvailable = computed(() =>
  isResolutionOptionAvailable(
    props.resolutionOptions,
    RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance,
  ),
)

const hasStraightRefundOptionAvailable = computed(() =>
  isResolutionOptionAvailable(
    props.resolutionOptions,
    RESOLUTION_OPTION_TYPE_ENUM.straightRefund,
  ),
)

const hasSubmitError = computed(() =>
  props.machineState.matches('RESOLUTION_OPTION.INTERCOM.LOADING_FAILED'),
)

function handleCloseErrorToast() {
  send('CLOSE')
}

const displayBundleInfo = computed(
  () =>
    hasBundlePlan.value &&
    (hasRefundOptionAvailable.value || hasStraightRefundOptionAvailable.value),
)

const formatedResolutionOptions = computed(() => {
  if (!hasLiveChatOptionAvailable.value) {
    return props.resolutionOptions.filter(
      (item) => item.type !== RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance,
    )
  }

  return props.resolutionOptions
})

function getResolutionOptionCard(type: GetResolutionOptionsTypeEnum) {
  if (type in resolutionOptionsConfig) {
    return resolutionOptionsConfig[type]
  }

  return undefined
}

function onConfirmBundledRefund() {
  const selectedOption = props.resolutionOptions.find(
    (option) => option.type === RESOLUTION_OPTION_TYPE_ENUM.refund,
  ) as ResolutionOption

  send({
    type: 'CONTINUE',
    payload: {
      resolutionOption: selectedOption,
    },
  })
}

function onContinue(event: GetResolutionOptionsTypeEnum) {
  const selectedOption = props.resolutionOptions.find(
    (option) => option.type === event,
  ) as ResolutionOption

  const requireConfirmation =
    hasBundlePlan.value &&
    selectedOption.type === RESOLUTION_OPTION_TYPE_ENUM.refund

  if (requireConfirmation) {
    openModal(RESOLUTION_FLOW_MODALS_NAMES.bundledServicesActiveModal)

    return
  }

  send({
    type: 'CONTINUE',
    payload: {
      resolutionOption: selectedOption,
    },
  })
}

const displayLoadingButton = computed(() =>
  props.machineState.hasTag('loading_button'),
)

function getHelpByEmail() {
  onContinue(RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance)
}
</script>
