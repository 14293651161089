import type { OrderlineTimelineResponse } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineTimelineResponse'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from '~/scopes/customer-care/resolution/components/ResolutionFlowScreens/screens/OrderlineLifecycle/OrderlineLifecycleScreen/OrderlineLifeCycleScreen.translations'

export const getInformativeStatusDuration = (
  orderlineTimeline: OrderlineTimelineResponse | null,
  stepType: string,
  isDayFormat: boolean,
  translation: I18nDefinition,
) => {
  const i18n = useI18n()
  const orderStep = orderlineTimeline?.find((step) => step.type === stepType)
  if (orderStep?.date) {
    const diffInHours = (d1: string, d2: string) =>
      Math.abs((new Date(d1).getTime() - new Date(d2).getTime()) / 36e5)
    const time = Math.round(
      isDayFormat
        ? diffInHours(orderStep.date, new Date().toISOString()) / 24
        : diffInHours(orderStep.date, new Date().toISOString()),
    )
    const duration = time === 0 ? 1 : time

    return i18n(translation, {
      duration,
    })
  }

  return i18n(translations.orderlineStatusInDayDuration, { duration: 1 })
}
