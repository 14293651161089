<template>
  <RevInputFileUpload
    id="contact-file-upload"
    ref="RevInputFileUploadRef"
    v-model="files"
    :accept="INPUT_FILE_UPLOAD_ACCEPTED_FILE_EXTENSIONS"
    data-qa="resolution-flow-input-file-upload"
    data-test="resolution-flow-input-file-upload"
    :description="
      i18n(translations.inputFileDescription, {
        size: humanReadableSize(MAX_FILE_SIZE, 'B'),
      })
    "
    :label-empty="i18n(translations.inputFileUploadEmptyLabel)"
    :label-not-empty="i18n(translations.inputFileUploadNoEmptyLabel)"
    :max-file-size="MAX_FILE_SIZE"
    :max-files-size="MAX_FILES_SIZE"
    @errors="handleInputFileErrors"
    @update:model-value="modelValueUpdated"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { humanReadableSize } from '@backmarket/utils/math/humanReadableSize'
import { RevInputFileUpload } from '@ds/components/InputFileUpload'
import type { FileError } from '@ds/components/InputFileUpload/InputFileUpload.type'

import {
  MAX_FILES_SIZE,
  MAX_FILE_SIZE,
} from '../../../machine/ResolutionFlow.machine.constants'

import {
  INPUT_FILE_UPLOAD_ACCEPTED_FILE_EXTENSIONS,
  INPUT_FILE_UPLOAD_ERROR_TYPES,
} from './InputFileUpload.constants'
import translations from './InputFileUpload.translations'

const emit = defineEmits<{
  'update:modelValue': [File[]]
}>()

const RevInputFileUploadRef = ref()

defineExpose({
  RevInputFileUploadRef,
})

const i18n = useI18n()
const { openErrorToast } = useTheToast()

const files = ref([])

function modelValueUpdated(value: File[]) {
  emit('update:modelValue', value)
}

function handleInputFileErrors(errors: FileError[]) {
  let fileErrorMessage = ''

  const isTooLarge = errors.some((error) =>
    [
      INPUT_FILE_UPLOAD_ERROR_TYPES.MAX_FILE_SIZE_EXCEEDED,
      INPUT_FILE_UPLOAD_ERROR_TYPES.MAX_FILES_SIZE_EXCEEDED,
    ].includes(error.type),
  )

  const isWrongFileType = errors.some(
    ({ type }) => type === INPUT_FILE_UPLOAD_ERROR_TYPES.WRONG_FILE_EXTENSION,
  )

  if (isTooLarge && isWrongFileType) {
    fileErrorMessage = i18n(translations.inputFileErrorWrongFileTypeAndTooLarge)
  } else if (isTooLarge) {
    fileErrorMessage = i18n(translations.inputFileErrorMaxSize)
  } else if (isWrongFileType) {
    fileErrorMessage = i18n(translations.inputFileErrorWrongFileType)
  }

  openErrorToast({
    title: i18n(translations.errorToastTitle),
    content: fileErrorMessage,
  })
}
</script>
