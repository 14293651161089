import {
  DECLARE_WARRANTY_STATE_ENUM,
  type GetDeclaredWarrantyStateEnum,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import translations from './WarrantyExclusionScreen.translations'

type WarrantyStateOptions = {
  dataQa: string
  id: string
  label: I18nDefinition<string>
  value: GetDeclaredWarrantyStateEnum
}[]

export const warrantyStateOptions: WarrantyStateOptions = [
  {
    dataQa: 'warranty-exclusion-water-or-dropped-radio',
    id: 'warranty-exclusion-water-or-dropped-radio',
    label: translations.waterOrDroppedOptionValue,
    value: DECLARE_WARRANTY_STATE_ENUM.waterOrDropped,
  },
  {
    dataQa: 'warranty-exclusion-third-party-repair-radio',
    id: 'warranty-exclusion-third-party-repair-radio',
    label: translations.thirdPartyRepairOptionValue,
    value: DECLARE_WARRANTY_STATE_ENUM.thirdPartyRepair,
  },
  {
    dataQa: 'warranty-exclusion-in-warranty-radio',
    id: 'warranty-exclusion-in-warranty-radio',
    label: translations.noneOptionValue,
    value: DECLARE_WARRANTY_STATE_ENUM.inWarranty,
  },
] as const
