<template>
  <main v-if="orderline" class="contents">
    <FailLoadingScreen
      v-if="displayFailLoadingScreen"
      data-qa="resolution-flow-fail-loading-screen"
      data-test="resolution-flow-fail-loading-screen"
      @retry="onRetry"
    />

    <InvalidProblemErrorScreen
      v-else-if="displayInvalidLoadingProblemContextErrorScreen"
    />

    <LoadingScreen
      v-else-if="displayLoadingScreen"
      data-qa="resolution-flow-loading-screen"
      data-test="resolution-flow-loading-screen"
      :loading-text="i18n(translations.initialLoadingText)"
    />

    <ResolutionFlowScreens
      v-else
      :machine-send="send"
      :machine-state="state"
      :order-id="orderline.orderId"
      :product-image="orderline.product.image"
      :product-title="orderline.product.title"
      @cancel="onCancel"
    />

    <CancellationModal />
  </main>
</template>

<script lang="ts" setup>
import {
  createError,
  navigateTo,
  useRoute,
  useRouteLocationWithLocale,
} from '#imports'
import { computed, onMounted, onUnmounted, provide, shallowRef } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { openModal } from '@ds/components/ModalBase'
import { interpret } from 'xstate'

import { useOrderlineDetails } from '@/scopes/care-commons/components/order/OrderlineCard/composables/useOrderlineDetails'
import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { CUSTOMER_CARE_MODALS_NAMES } from '~/scopes/customer-care/common/modals/names'
import { ResolutionFlowStateMachineKey } from '~/scopes/customer-care/resolution/symbols'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import FailLoadingScreen from '../common/components/FailLoadingScreen/FailLoadingScreen.vue'
import InvalidProblemErrorScreen from '../common/components/InvalidProblemErrorScreen/InvalidProblemErrorScreen.vue'
import LoadingScreen from '../common/components/LoadingScreen/LoadingScreen.vue'
import CancellationModal from '../common/modals/CancellationModal/CancellationModal.vue'
import { decodeIssues } from '../common/utils/issueCommunication'

import translations from './ResolutionFlow.translations'
import ResolutionFlowScreens from './components/ResolutionFlowScreens/ResolutionFlowScreens.vue'
import { createResolutionFlowMachine } from './machine/ResolutionFlow.machine'

const i18n = useI18n()

const { params, matched } = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const {
  execute,
  data: orderline,
  error,
} = await useOrderlineDetails({
  orderlineId: params.orderlineId as string,
})

await execute()

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}

const decodedCustomerIssues = decodeIssues({
  issuesBase64String: params.customerIssues as string,
  scope: SALES_CUSTOMER_CARE_SCOPE.resolutionFlow,
})

const resolutionFlowMachine = createResolutionFlowMachine(
  'GET_DIAGNOSIS_ACTION',
)

const state = shallowRef(resolutionFlowMachine.initialState)

const service = interpret(
  resolutionFlowMachine.withContext({
    ...resolutionFlowMachine.context,
    diagnosisV2CustomerIssues: decodedCustomerIssues?.selectedIssues,
    diagnosisV2CustomerIssuesTags: decodedCustomerIssues?.tags,
    orderline: orderline.value ?? null,
    orderlineId: orderline.value?.orderlineId ?? null,
    publicId: orderline.value?.publicId ?? null,
  }),
  {
    devTools: false,
  },
).onTransition((newState) => {
  state.value = newState
})

const { send } = service

provide(ResolutionFlowStateMachineKey, {
  state,
  send,
})

onMounted(() => {
  service.start()
})

onUnmounted(() => {
  service.stop()
})

const displayFailLoadingScreen = computed(() =>
  state.value.hasTag('loading_failed'),
)

const displayInvalidLoadingProblemContextErrorScreen = computed(() =>
  state.value.hasTag('loading_problem_context_failed'),
)

const displayLoadingScreen = computed(() => state.value.hasTag('loading'))

function onRetry() {
  send('RETRY')
}

function onCancel() {
  openModal(CUSTOMER_CARE_MODALS_NAMES.cancellationModal)
}

if (
  matched.some(
    ({ name }) =>
      name === CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.SELF &&
      name !== CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.PAYMENT,
  )
) {
  navigateTo(
    resolveLocalizedRoute({
      name: CUSTOMER_CARE.RESOLUTION.SELF,
    }) as RouteLocationRaw,
    { replace: true },
  )
}
</script>
