export default {
  title1: {
    id: 'dashboard_diagnosis_bundle_info_title1',
    defaultMessage: 'Your item is in a bundle',
  },
  title2: {
    id: 'dashboard_diagnosis_bundle_info_title2',
    defaultMessage: 'Bundled services stay active',
  },
  text1: {
    id: 'dashboard_diagnosis_bundle_info_text1',
    defaultMessage:
      'Cancelling this item will also cancel any bundled services like mobile or insurance plans.',
  },
  text2: {
    id: 'dashboard_diagnosis_bundle_info_text2',
    defaultMessage:
      'Your device can be returned, but your bundled services will stay active. Check your provider’s account for information.',
  },
}
